import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import { exportExcel, getInvoicing, getQueryParamsObj } from '@/api'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import LoadingBox from '@/components/baseMap/loadingBox'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import dayjs from 'dayjs'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import UnconfirmedBillsCollapseBox from '@/components/adminBox/unconfirmedBillsBox/unconfirmedBillsCollapseBox'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0.5vh',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.5vh',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '1.7vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledSumTableCell = withStyles(TableCell, (theme) => ({
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '2vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '12.5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&>div:nth-of-type(2)': {
      height: '46%',
      width: '100%',
      '@media (max-width: 1280px)': {
        height: 'auto',
      },
    },
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '40%',
    },
  },
  title1: {
    '@media (max-width: 1280px)': {
      height: '30% !important',
    },
  },
  iphoneBox: {
    position: 'relative',
    height: '87.5%',
    '@media (max-width: 1280px)': {
      height: '60%',
      overflow: 'auto',
    },
  },
  iphoneBox1: {
    '@media (max-width: 1280px)': {
      height: '70% !important',
    },
  },
  selectBigBox: {
    width: '18%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1280px)': {
      width: '100% !important',
      height: '15%',
    },
  },
  selectBigBox1: {
    width: '19%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1280px)': {
      width: '100% !important',
      height: '20% !important',
    },
  },
  titleText: {
    width: '8vh',
    fontSize: '2.5vh',
    fontWeight: 600,
    '@media (max-width: 1280px)': {
      width: '10vh',
    },
  },
  dateBox: {
    height: '100%',
    width: '75%',
    '&>div': {
      height: '100%',
      padding: 0,
      '&>div': {
        height: '100%',
        minWidth: 'auto !important',
        '&>div': {
          height: '100%',
          padding: 0,
          '&>input': {
            padding: '0 10px',
          },
          '&>fieldset': {
            top: '-4px',
            border: '2px solid #000 !important',
          },
        },
      },
    },
    '&>input': {
      height: '100%',
      width: '100%',
      textAlign: 'center',
      color: '#000',
      fontSize: 20,
      padding: 0,
      border: '2px solid #000',
      borderRadius: 5,
      background: 'transparent',
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      '&::before,&::after': {
        display: 'none',
      },
    },
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  iptModal: {
    height: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  iptModalDelete: {
    '&>div>input': {
      width: '88% !important',
    },
  },
  selectBox: {
    width: '60%',
    height: '100%',
    position: 'relative',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  selectBox1: {
    width: '70%',
    height: '100%',
    position: 'relative',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  iconBox: {
    width: '3vh',
    height: '3vh',
    position: 'absolute',
    right: 4,
    top: 'calc( 50% - 1.5vh )',
    color: '#aaa',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  btn: {
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  titleLeftBox: {
    display: 'flex',
    color: '#000',
    height: '46%',
    width: '100%',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '80%',
      width: '90%',
      justifyContent: 'space-between',
    },
  },
  titleLeftBox1: {
    '@media (max-width: 1280px)': {
      height: '75% !important',
    },
  },
  tableBox: {
    overflow: 'auto',
    minWidth: 1200,
  },
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '34px',
    '&>svg': {
      width: '3vh',
      height: '3vh',
      '@media (max-width: 1280px)': {
        width: '2vh',
        height: '2vh',
      },
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  thBox1: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    lineHeight: '34px',
    '&:hover': {
      cursor: 'pointer',
    },
    '&>svg': {
      width: '3vh',
      height: '3vh',
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  loadBox: {
    position: 'absolute',
    zIndex: 10,
    left: 0,
    right: 0,
    top: '40%',
    margin: 'auto',
  },
  totalBox: {
    display: 'flex',
    width: '100%',
    background: '#fff',
    borderRadius: ' 0 0 5px 5px',
    color: '#000',
    minWidth: 1200,
    '&>div': {
      width: '7%',
      padding: '1vh 0.5vh',
      fontSize: '2vh',
      lineHeight: 1.5,
      fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
      fontWeight: 600,
      textAlign: 'left',
      '@media (max-width: 1280px)': {
        fontSize: '1.6vh',
      },
      '&:nth-of-type(2)': {
        width: '15.5%',
      },
      '&:nth-of-type(3)': {
        width: '6%',
      },
      '&:nth-of-type(7)': {
        width: '10.5%',
      },
    },
  },
  sumBox: {
    fontSize: '1.8vh',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
  thFont: {
    fontWeight: 600,
  },
}))

interface UnconfirmedBillsBoxProps {
  urlName: any
  divWidth: any
}

let timer: NodeJS.Timeout
const UnconfirmedBillsBox = (props: UnconfirmedBillsBoxProps) => {
  const { urlName, divWidth } = props
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [moneyValue, setMoneyValue] = useState<any>('')
  const [totalObj, setTotalObj] = useState<any>({
    amount: 0,
    tax: 0,
  })
  const [loadBoo, setLoadBoo] = useState(true)
  const [selectValue] = useState('转账')
  const [arrList, setArrList] = useState<any>([])
  const [arrNameList, setArrNameList] = useState<any>([])
  const [arrExportList, setArrExportList] = useState<any>({})
  const dateObj = getQueryParamsObj(window.location?.search)
  const [dateValue, setDateValue] = useState<any>(['', ''])
  const [allTotalObj, setAllTotalObj] = useState<any>({})
  const [paddingBoo, setPaddingBoo] = useState(false)

  const tableBigBox = document.getElementById('tableBigBox')
  const totalBox = document.getElementById('totalBox')
  const tableBox = document.getElementById('tableBox')

  const headerArr = urlName.includes('management')
    ? [
        {
          key: 0,
          name: '小区名',
          width: '7%',
        },
        {
          key: 1,
          name: '收费项目',
          width: '15.5%',
        },
        {
          key: 2,
          name: '日期',
          width: '6%',
        },
        {
          key: 3,
          name: '小票',
          width: '13.5%',
        },
        {
          key: 4,
          name: '发票',
          width: '13.5%',
        },
        {
          key: 5,
          name: '购买方',
          width: '18%',
        },
        {
          key: 6,
          name: '业主',
          width: '10.5%',
        },
        {
          key: 7,
          name: '金额',
          width: '8%',
        },
        {
          key: 8,
          name: '税费',
          width: '8%',
        },
      ]
    : [
        {
          key: 0,
          name: '小区名',
          width: '7%',
        },
        {
          key: 1,
          name: '收费项目',
          width: '15.5%',
        },
        {
          key: 2,
          name: '日期',
          width: '6%',
        },
        {
          key: 3,
          name: '小票',
          width: '10%',
        },
        {
          key: 4,
          name: '发票',
          width: '10%',
        },
        {
          key: 5,
          name: '购买方',
          width: '14%',
        },
        {
          key: 6,
          name: '业主',
          width: '10.5%',
        },
        {
          key: 7,
          name: '收款确认',
          width: '11%',
        },
        {
          key: 8,
          name: '金额',
          width: '8%',
        },
        {
          key: 9,
          name: '税费',
          width: '8%',
        },
      ]

  const exportObj = (res: any) => {
    let arrObj: any = {}
    arrObj = {
      '!cols': [],
      '!fullref': `A1:I${(res?.length || 0) + 1}`,
      '!ref': `A1:I${(res?.length || 0) + 1}`,
      '!rows': [],
      A1: { t: 's', v: '小区名' },
      B1: { t: 's', v: '收费项目' },
      C1: { t: 's', v: '日期' },
      D1: { t: 's', v: '小票' },
      E1: { t: 's', v: '发票' },
      F1: { t: 's', v: '购买方' },
      G1: { t: 's', v: '业主' },
      H1: { t: 's', v: '金额' },
      I1: { t: 's', v: '税费' },
    }
    res?.forEach((item: any, i: number) => {
      arrObj[`A${i + 2}`] = { t: 's', v: item.projectName || '' }
      arrObj[`B${i + 2}`] = { t: 's', v: item.billLxName || '' }
      arrObj[`C${i + 2}`] = { t: 's', v: item.receiptDate || '' }
      arrObj[`D${i + 2}`] = { t: 's', v: item.receiptNumber || '' }
      arrObj[`E${i + 2}`] = { t: 's', v: item.invoiceNumber || '' }
      arrObj[`F${i + 2}`] = { t: 's', v: item.buyer || '' }
      arrObj[`G${i + 2}`] = { t: 's', v: item.ownerName || '' }
      arrObj[`H${i + 2}`] = { t: 'n', v: item.amount || 0 }
      arrObj[`I${i + 2}`] = { t: 'n', v: item.tax || 0 }
    })
    setArrExportList(arrObj)
  }

  const getUnconfirmedBillsArr = (date: any, receiptMethod: any, projectId: any) => {
    setArrList([])
    setArrNameList([])
    getInvoicing(date[0], date[1], receiptMethod, projectId, true)
      .then((res: any) => {
        const newRes =
          moneyValue !== '' && moneyValue
            ? res?.filter((items: any) => items?.ownerName?.includes(moneyValue) && !items?.refundDate)
            : res?.filter((items: any) => !items?.refundDate)

        if (newRes?.length < 1) {
          setTotalObj({
            amount: 0,
            tax: 0,
          })
        }
        let amountNum: any = 0
        let taxNum: any = 0
        let newObj: any = {}
        const newArr: any[] = []
        const newNameArr: any[] = []
        newRes?.map((item: any, i: any) => {
          amountNum += item.amount
          taxNum += item.tax
          newNameArr.push(item.projectName)
          if (i === newRes?.length - 1) {
            setTotalObj({
              amount: amountNum,
              tax: taxNum,
            })
          }
        })
        Array.from(new Set(newNameArr))?.map((item) => {
          newArr.push({ projectName: item, open: false })
        })
        newArr?.map((item) => {
          newRes?.map((items: any) => {
            if (item?.projectName === items?.projectName) {
              newObj = {
                ...newObj,
                [item?.projectName]: {
                  amount: (newObj[item?.projectName]?.amount || 0) + items.amount,
                  tax: (newObj[item?.projectName]?.tax || 0) + items.tax,
                },
              }
            }
          })
        })

        setLoadBoo(false)
        setAllTotalObj(newObj)
        setArrList(newRes)
        setArrNameList(newArr)
        exportObj(newRes)
      })
      .catch(console.error)
  }

  const getPaddingBoo = () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      if (tableBigBox?.clientHeight && tableBox?.clientHeight && arrList?.length > 0) {
        if (tableBox?.clientHeight <= tableBigBox?.clientHeight) {
          !paddingBoo && setPaddingBoo(true)
        } else {
          paddingBoo && setPaddingBoo(false)
        }
      }
    }, 350)
  }

  useEffect(() => {
    if (tableBigBox?.clientHeight && tableBox?.clientHeight && arrList?.length > 0) {
      if (tableBox?.clientHeight <= tableBigBox?.clientHeight) {
        !paddingBoo && setPaddingBoo(true)
      } else {
        paddingBoo && setPaddingBoo(false)
      }
    }
  }, [tableBigBox?.clientHeight, tableBox?.clientHeight, arrList])

  useEffect(() => {
    setArrList([])
    setPaddingBoo(true)
    setTotalObj({
      amount: 0,
      tax: 0,
    })
    if (dateObj?.id && dateObj?.typeValue === '29') {
      if (urlName?.includes('management')) {
        getUnconfirmedBillsArr(dateValue, selectValue, dateObj?.id)
      } else {
        getUnconfirmedBillsArr(dateValue, selectValue, '')
      }
    }
  }, [dateObj?.id, dateObj?.typeValue])

  useEffect(() => {
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div className={classes.root}>
      <div className={urlName.includes('management') ? classNames([classes.title, classes.title1]) : classes.title}>
        <div
          className={
            urlName.includes('management')
              ? classNames([classes.titleLeftBox, classes.titleLeftBox1])
              : classes.titleLeftBox
          }
        >
          {urlName.includes('admin') && (
            <div style={{ width: '22%' }} className={classes.selectBigBox}>
              <div className={classes.titleText}>时间：</div>
              <div className={classes.dateBox}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['SingleInputDateRangeField']}>
                    <DateRangePicker
                      disableFuture
                      format="YYYY/MM/DD"
                      value={[dayjs(dateValue[0]), dayjs(dateValue[1])]}
                      onChange={(e: any) => {
                        const dateOne = e[0] && e[0] !== null ? dayjs(e[0]).format('YYYY-MM-DD') : ''
                        const dateTwo = e[1] && e[1] !== null ? dayjs(e[1]).format('YYYY-MM-DD') : ''
                        setDateValue([dateOne, dateTwo])
                      }}
                      slots={{ field: SingleInputDateRangeField }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          )}
          <div className={urlName.includes('management') ? classes.selectBigBox1 : classes.selectBigBox}>
            <div className={classes.titleText}>业主： </div>
            <div className={urlName.includes('management') ? classes.selectBox1 : classes.selectBox}>
              <TextField
                className={classNames([classes.iptModal, classes.iptModalDelete])}
                value={moneyValue}
                onChange={(event: any) => {
                  setMoneyValue(event.target.value)
                }}
                id="outlined-basic"
                variant="outlined"
              />
              {moneyValue !== '' && (
                <HighlightOffIcon
                  className={classes.iconBox}
                  onClick={() => {
                    setMoneyValue('')
                  }}
                />
              )}
            </div>
          </div>
          <div className={urlName.includes('management') ? classes.selectBigBox1 : classes.selectBigBox}>
            <div style={{ width: '10vh' }} className={classes.titleText}>
              购买方：{' '}
            </div>
            <div className={urlName.includes('management') ? classes.selectBox1 : classes.selectBox}>
              <TextField
                className={classNames([classes.iptModal, classes.iptModalDelete])}
                value={moneyValue}
                onChange={(event: any) => {
                  setMoneyValue(event.target.value)
                }}
                id="outlined-basic"
                variant="outlined"
              />
              {moneyValue !== '' && (
                <HighlightOffIcon
                  className={classes.iconBox}
                  onClick={() => {
                    setMoneyValue('')
                  }}
                />
              )}
            </div>
          </div>
          <div className={urlName.includes('management') ? classes.selectBigBox1 : classes.selectBigBox}>
            <div className={classes.titleText}>金额： </div>
            <div className={urlName.includes('management') ? classes.selectBox1 : classes.selectBox}>
              <TextField
                className={classNames([classes.iptModal, classes.iptModalDelete])}
                value={moneyValue}
                onChange={(event: any) => {
                  setMoneyValue(event.target.value)
                }}
                id="outlined-basic"
                variant="outlined"
              />
              {moneyValue !== '' && (
                <HighlightOffIcon
                  className={classes.iconBox}
                  onClick={() => {
                    setMoneyValue('')
                  }}
                />
              )}
            </div>
          </div>
          <div
            style={{ width: '22%' }}
            className={urlName.includes('management') ? classes.selectBigBox1 : classes.selectBigBox}
          >
            <div style={{ width: '10vh' }} className={classes.titleText}>
              流水号：
            </div>
            <div className={urlName.includes('management') ? classes.selectBox1 : classes.selectBox}>
              <TextField
                className={classNames([classes.iptModal, classes.iptModalDelete])}
                value={moneyValue}
                onChange={(event: any) => {
                  setMoneyValue(event.target.value)
                }}
                id="outlined-basic"
                variant="outlined"
              />
              {moneyValue !== '' && (
                <HighlightOffIcon
                  className={classes.iconBox}
                  onClick={() => {
                    setMoneyValue('')
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              setLoadBoo(true)
              if (urlName?.includes('management')) {
                getUnconfirmedBillsArr(dateValue, selectValue, dateObj?.id)
              } else {
                getUnconfirmedBillsArr(dateValue, selectValue, '')
              }
              navigate(`?id=${dateObj?.id || 101}&typeValue=${dateObj?.typeValue}`)
            }}
            variant="contained"
            className={classes.btn}
          >
            查询
          </Button>
          {divWidth > 1280 && (
            <Button
              style={{ marginLeft: 15 }}
              onClick={() => {
                if (selectValue !== '' && dateValue !== '' && arrList?.length > 0) {
                  exportExcel(
                    arrExportList,
                    `${dateValue[0]}到${dateValue[1]}的${selectValue}未确认收款票据-${moment
                      .utc(new Date())
                      .format('YYYY-MM-DD')}.xlsx`,
                  )
                }
              }}
              variant="contained"
              className={classes.btn}
            >
              导出
            </Button>
          )}
        </div>
      </div>
      <div
        className={
          urlName.includes('management') ? classNames([classes.iphoneBox, classes.iphoneBox1]) : classes.iphoneBox
        }
      >
        {loadBoo && (
          <div className={classes.loadBox}>
            <LoadingBox />
          </div>
        )}
        <div
          id="tableBigBox"
          style={{
            maxHeight: `calc( 100% - ${totalBox?.clientHeight}px )`,
          }}
          className={classes.tableBox}
        >
          <Table id="tableBox" stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                {headerArr?.map((item: any, i: any) => {
                  return (
                    <StyledTableCell
                      style={{ width: item.width }}
                      key={item.key}
                      align={
                        urlName?.includes('admin')
                          ? i === 8 || i === 9
                            ? 'right'
                            : 'left'
                          : i === 7 || i === 8
                          ? 'right'
                          : 'left'
                      }
                    >
                      {item.name}
                    </StyledTableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {arrNameList?.map((item: any, i: number) => {
                return (
                  <React.Fragment key={i}>
                    <TableRow>
                      <StyledTableCell align="left">
                        <div
                          onClick={() => {
                            const newBooArr: any[] = []
                            arrNameList?.map((items: any) => {
                              if (items.projectName === item.projectName) {
                                newBooArr.push({ ...items, open: !items.open })
                              } else {
                                newBooArr.push(items)
                              }
                            })
                            setArrNameList([...newBooArr])
                            getPaddingBoo()
                          }}
                          className={classes.thBox1}
                        >
                          {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          {item.projectName || ''}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="left" />
                      <StyledTableCell align="left" />
                      <StyledTableCell align="left" />
                      <StyledTableCell align="left" />
                      <StyledTableCell align="left" />
                      <StyledTableCell align="left" />
                      {urlName?.includes('admin') && <StyledTableCell align="left" />}
                      <StyledTableCell align="right">
                        <div className={classes.thBox}>
                          {!item.open ? allTotalObj[item?.projectName]?.amount?.toFixed(2) || 0 : ''}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <div className={classes.thBox}>
                          {!item.open ? allTotalObj[item?.projectName]?.tax?.toFixed(2) || 0 : ''}
                        </div>
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell
                        style={{ padding: 0 }}
                        align="left"
                        colSpan={urlName?.includes('admin') ? 10 : 9}
                      >
                        <UnconfirmedBillsCollapseBox
                          urlName={urlName}
                          openBoo={item.open}
                          allArr={arrList?.filter((res: any) => res.projectName === item.projectName)}
                        />
                      </StyledTableCell>
                    </TableRow>
                    {item.open && (
                      <TableRow>
                        <StyledSumTableCell align="left">
                          <div className={classNames([classes.thBox, classes.thFont, classes.sumBox])}>
                            {`${item.projectName} 求和` || ''}
                          </div>
                        </StyledSumTableCell>
                        <StyledSumTableCell align="left" />
                        <StyledSumTableCell align="left" />
                        <StyledSumTableCell align="left" />
                        <StyledSumTableCell align="left" />
                        <StyledSumTableCell align="left" />
                        <StyledSumTableCell align="left" />
                        {urlName?.includes('admin') && <StyledSumTableCell align="left" />}
                        <StyledSumTableCell align="right">
                          <div className={classNames([classes.thBox, classes.thFont])}>
                            {allTotalObj[item?.projectName]?.amount?.toFixed(2) || 0}
                          </div>
                        </StyledSumTableCell>
                        <StyledSumTableCell align="right">
                          <div className={classNames([classes.thBox, classes.thFont])}>
                            {allTotalObj[item?.projectName]?.tax?.toFixed(2) || 0}
                          </div>
                        </StyledSumTableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                )
              })}
            </TableBody>
          </Table>
        </div>
        <div id="totalBox" style={{ paddingRight: paddingBoo ? 0 : 15 }} className={classes.totalBox}>
          <div>总计</div>
          <div />
          <div />
          <div style={{ width: urlName?.includes('admin') ? '10%' : '13.5%' }} />
          <div style={{ width: urlName?.includes('admin') ? '10%' : '13.5%' }} />
          <div style={{ width: urlName?.includes('admin') ? '14%' : '18%' }} />
          <div />
          {urlName?.includes('admin') && <div style={{ width: '11%' }} />}
          <div style={{ width: '8%', textAlign: 'right' }}>{totalObj?.amount?.toFixed(2) || 0}</div>
          <div style={{ width: '8%', textAlign: 'right' }}>{totalObj?.tax?.toFixed(2) || 0}</div>
        </div>
      </div>
    </div>
  )
}

export default UnconfirmedBillsBox
