import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import { Collapse } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import ReceivingPaymentBox from '@/components/adminBox/unconfirmedBillsBox/receivingPaymentBox'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0.5vh',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.5vh',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '1.7vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const useStyles = makeStyles()(() => ({
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '34px',
    '&>svg': {
      width: '3vh',
      height: '3vh',
      '@media (max-width: 1280px)': {
        width: '2vh',
        height: '2vh',
      },
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
}))

interface UnconfirmedBillsCollapseBoxProps {
  openBoo: boolean
  allArr: any
  urlName: any
}

const UnconfirmedBillsCollapseBox = (props: UnconfirmedBillsCollapseBoxProps) => {
  const { classes } = useStyles()
  const { openBoo, allArr, urlName } = props
  const [childArr, setChildArr] = useState<any>([])

  const getDateObj = (obj: any) => {
    const newArr: any = []
    childArr?.map((item: any) => {
      if (obj.receiptNumber === item.receiptNumber) {
        newArr.push({ ...item, payTime: obj.date, transactionId: obj.transactionId, status: obj.status })
      } else {
        newArr.push(item)
      }
    })
    setChildArr(newArr)
  }

  useEffect(() => {
    setChildArr(allArr)
  }, [])

  return (
    <Collapse in={openBoo} timeout={300} unmountOnExit>
      <Table stickyHeader aria-label="customized table">
        <TableBody>
          {childArr?.map((item: any, i: any) => {
            return (
              <TableRow key={i}>
                <StyledTableCell style={{ width: '7%' }} align="left" />
                <StyledTableCell style={{ width: '15.5%' }} align="left">
                  {item?.billLxName}
                </StyledTableCell>
                <StyledTableCell style={{ width: '6%' }} align="left">
                  {item?.receiptDate}
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: urlName?.includes('admin') ? '10%' : '13.5%', overflowWrap: 'anywhere' }}
                  align="left"
                >
                  {item?.receiptNumber}
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: urlName?.includes('admin') ? '10%' : '13.5%', overflowWrap: 'anywhere' }}
                  align="left"
                >
                  {item?.invoiceNumber}
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: urlName?.includes('admin') ? '14%' : '18%', overflowWrap: 'anywhere' }}
                  align="left"
                >
                  {item?.buyer}
                </StyledTableCell>
                <StyledTableCell style={{ width: '10.5%' }} align="left">
                  {item?.ownerName}
                </StyledTableCell>
                {urlName?.includes('admin') && (
                  <StyledTableCell style={{ width: '11%' }} align="left">
                    <ReceivingPaymentBox
                      transactionId={item?.transactionId}
                      receiptNumber={item?.receiptNumber}
                      label={item?.status}
                      payTime={item?.payTime}
                      getDateObj={getDateObj}
                    />
                  </StyledTableCell>
                )}

                <StyledTableCell style={{ width: '8%' }} align="right">
                  <div className={classes.thBox}>{item?.amount?.toFixed(2) || 0}</div>
                </StyledTableCell>
                <StyledTableCell style={{ width: '8%' }} align="right">
                  <div className={classes.thBox}>{item?.tax?.toFixed(2) || 0}</div>
                </StyledTableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Collapse>
  )
}

export default UnconfirmedBillsCollapseBox
