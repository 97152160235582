import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import CreateIcon from '@mui/icons-material/Create'
import Modal from '@mui/material/Modal'
import ModelBox from 'src/components/adminBox/ModalBox'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import DoneIcon from '@mui/icons-material/Done'

const useStyles = makeStyles()(() => ({
  operateBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  groupBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconBox: {
    color: '#fff',
    background: '#214162',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
    borderRadius: 5,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    '&:hover': {
      background: '#214162',
      cursor: 'pointer',
    },
    '&:active': {
      background: '#1976d2',
      boxShadow: 'inset 2px 2px 5px -1px rgba(0, 0, 0, 0.2),inset -2px -2px 5px -1px rgba(0, 0, 0, 0.12)',
    },
    '&>svg': {
      width: '2vh',
      height: '2vh',
    },
  },
  modalBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalSmallBox: {
    minWidth: 350,
    borderRadius: 15,
    minHeight: '25%',
    background: '#fff',
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))

interface BigBoxProps {
  label: any
  receiptNumber: any
  payTime: any
  transactionId: any
  getDateObj: (obj: any) => void
}

const BigBox = (props: BigBoxProps) => {
  const { classes } = useStyles()
  const { label, receiptNumber, payTime, transactionId, getDateObj } = props
  const [dateNum, setDateNum] = useState<any>('')
  const [timeBoo, setTimeBoo] = useState(false)
  const [open, setOpen] = useState<any>(false)
  const [idNum, setIdNum] = useState<any>('')

  const getOpen = (boo: any) => {
    setOpen(boo)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setDateNum(payTime)
  }, [payTime])

  useEffect(() => {
    setIdNum(transactionId)
  }, [transactionId])

  useEffect(() => {
    setTimeBoo(label === '已收款')
  }, [label])

  return (
    <div className={classes.operateBox}>
      <div className={classes.groupBox}>
        <div>{dateNum ? dateNum : '/年/月/日'}</div>
        {idNum && idNum !== '' && <BookmarkIcon />}
        {timeBoo && <DoneIcon />}
      </div>
      <div>
        <div onClick={() => setOpen(true)} className={classes.iconBox}>
          <CreateIcon />
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        className={classes.modalBox}
      >
        <div className={classes.modalSmallBox}>
          <ModelBox
            transactionId={idNum}
            timeBoo={timeBoo}
            receiptNumber={receiptNumber}
            payTime={dateNum}
            getOpen={getOpen}
            getDateObj={getDateObj}
          />
        </div>
      </Modal>
    </div>
  )
}

export default BigBox
