import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import { Collapse } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import classNames from 'classnames'
import CollapseChildBox from '@/components/adminBox/invoicingListBox/invoicingCollapseBox/collapseChildBox'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  body: {
    width: '9%',
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '1.7vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledSumTableCell = withStyles(TableCell, (theme) => ({
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '2vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  btnBox: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      background: '#232425',
      color: '#fff',
    },
  },
  btnBoxBg: {
    background: '#232425',
    color: '#fff',
  },
  thBox: {
    lineHeight: '34px',
    '&>svg': {
      width: '3vh',
      height: '3vh',
      '@media (max-width: 1280px)': {
        width: '2vh',
        height: '2vh',
      },
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  thBox1: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    lineHeight: '34px',
    '&:hover': {
      cursor: 'pointer',
    },
    '&>svg': {
      width: '3vh',
      height: '3vh',
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  thFont: {
    fontWeight: 600,
  },
  sumBox: {
    fontSize: '1.8vh',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

interface CollapseBoxProps {
  openBoo: boolean
  allArr: any
  getPaddingBoo: () => void
  selectValue: any
}

const InvoicingCollapseBox = (props: CollapseBoxProps) => {
  const { classes } = useStyles()
  const { openBoo, allArr, getPaddingBoo, selectValue } = props

  const [arrBillLxNameList, setArrBillLxNameList] = useState<any>([])
  const [childArr, setChildArr] = useState<any>([])
  const [allTotalObj, setAllTotalObj] = useState<any>({})

  useEffect(() => {
    if (allArr?.length > 0 && openBoo) {
      const billLxNameArr: any[] = []
      const newArr: any[] = []
      let newObj: any = {}
      allArr?.map((item: any) => {
        billLxNameArr.push(item.billLxName)
      })
      Array.from(new Set(billLxNameArr))?.map((item) => {
        newArr.push({ billLxName: item, open: false })
      })
      newArr?.map((item) => {
        allArr?.map((items: any) => {
          if (item?.billLxName === items?.billLxName) {
            newObj = {
              ...newObj,
              [item?.billLxName]: {
                amount: (newObj[item?.billLxName]?.amount || 0) + items.amount,
                tax: (newObj[item?.billLxName]?.tax || 0) + items.tax,
              },
            }
          }
        })
      })
      setArrBillLxNameList(newArr)
      setAllTotalObj(newObj)
    }
  }, [openBoo])

  useEffect(() => {
    setChildArr(allArr)
  }, [allArr])

  const getDateObj = (obj: any) => {
    const newArr: any = []
    childArr?.map((item: any) => {
      if (obj.receiptNumber === item.receiptNumber) {
        newArr.push({ ...item, payTime: obj.date, transactionId: obj.transactionId, status: obj.status })
      } else {
        newArr.push(item)
      }
    })
    setChildArr(newArr)
  }

  useEffect(() => {
    if (!openBoo) {
      const newBooArr: any[] = []
      arrBillLxNameList?.map((items: any) => {
        newBooArr.push({ ...items, open: false })
      })
      setArrBillLxNameList([...newBooArr])
    }
  }, [openBoo])

  return (
    <Collapse in={openBoo} timeout={300} unmountOnExit>
      <Table stickyHeader aria-label="customized table">
        <TableBody>
          {arrBillLxNameList?.map((item: any, i: any) => {
            return (
              <React.Fragment key={i}>
                <StyledTableRow>
                  <StyledTableCell align="right" />
                  <StyledTableCell style={{ width: '12%' }} align="right">
                    <div
                      onClick={() => {
                        const newBooArr: any[] = []
                        arrBillLxNameList?.map((items: any) => {
                          if (items.billLxName === item.billLxName) {
                            newBooArr.push({ ...items, open: !items.open })
                          } else {
                            newBooArr.push(items)
                          }
                        })
                        setArrBillLxNameList([...newBooArr])
                        getPaddingBoo()
                      }}
                      className={classes.thBox1}
                    >
                      {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      {item?.billLxName}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '7%' }} align="right" />
                  <StyledTableCell style={{ width: '10%' }} align="right" />
                  <StyledTableCell style={{ width: '10%' }} align="right" />
                  <StyledTableCell style={{ width: '14%' }} align="right" />
                  <StyledTableCell style={{ width: '5.5%' }} align="right" />
                  <StyledTableCell style={{ width: '11%' }} align="right" />
                  <StyledTableCell style={{ width: '8.5%' }} align="right" />
                  <StyledTableCell style={{ width: '6.5%' }} align="right">
                    <div className={classes.thBox}>
                      {!item.open ? allTotalObj[item?.billLxName]?.amount?.toFixed(2) || 0 : ''}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '6.5%' }} align="right">
                    <div className={classes.thBox}>
                      {!item.open ? allTotalObj[item?.billLxName]?.tax?.toFixed(2) || 0 : ''}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell style={{ padding: 0 }} align="right" colSpan={11}>
                    <CollapseChildBox
                      selectValue={selectValue}
                      openBoo={item.open}
                      allArr={childArr?.filter((res: any) => res.billLxName === item.billLxName)}
                      getDateObj={getDateObj}
                    />
                  </StyledTableCell>
                </StyledTableRow>
                {item.open && (
                  <StyledTableRow>
                    <StyledSumTableCell style={{ width: '9%' }} align="left">
                      <div className={classNames([classes.thBox, classes.thFont, classes.sumBox])}>
                        {`${item.billLxName} 求和` || ''}
                      </div>
                    </StyledSumTableCell>
                    <StyledSumTableCell style={{ width: '12%' }} align="left" />
                    <StyledSumTableCell style={{ width: '7%' }} align="left" />
                    <StyledSumTableCell style={{ width: '10%' }} align="right" />
                    <StyledSumTableCell style={{ width: '10%' }} align="right" />
                    <StyledSumTableCell style={{ width: '14%' }} align="right" />
                    <StyledSumTableCell style={{ width: '5.5%' }} align="right" />
                    <StyledSumTableCell style={{ width: '11%' }} align="right" />
                    <StyledSumTableCell style={{ width: '8.5%' }} align="right" />
                    <StyledSumTableCell style={{ width: '6.5%' }} align="right">
                      <div className={classNames([classes.thBox, classes.thFont])}>
                        {allTotalObj[item?.billLxName]?.amount?.toFixed(2) || 0}
                      </div>
                    </StyledSumTableCell>
                    <StyledSumTableCell style={{ width: '6.5%' }} align="right">
                      <div className={classNames([classes.thBox, classes.thFont])}>
                        {allTotalObj[item?.billLxName]?.tax?.toFixed(2) || 0}
                      </div>
                    </StyledSumTableCell>
                  </StyledTableRow>
                )}
              </React.Fragment>
            )
          })}
        </TableBody>
      </Table>
    </Collapse>
  )
}

export default InvoicingCollapseBox
