import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { exportExcel, getProjectList, getQueryParamsObj, getWeeklySummary, tabExportExcel } from '@/api'
import moment from 'moment'
import { Collapse, FormControl, MenuItem, Select } from '@mui/material'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import '@/font/simhei-normal'
import OneTableBox from '@/components/adminBox/weeklySummaryBox/oneTableBox'
import LoadingBox from '@/components/baseMap/loadingBox'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import dayjs from 'dayjs'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '7%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '30%',
    },
  },
  titleManagement: {
    padding: '10px 0',
    width: '100%',
    height: '7%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '20%',
    },
  },
  titleLeftBox: {
    display: 'flex',
    color: '#000',
    height: '100%',
    width: '60%',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '70%',
      width: '100%',
    },
  },
  selectBigBox: {
    width: '26%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    '@media (max-width: 1280px)': {
      width: '100%',
      height: '22%',
      marginRight: 0,
      marginBottom: 10,
    },
  },
  selectBigBox1: {
    width: '30%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    '@media (max-width: 1280px)': {
      width: '100%',
      height: '22%',
      marginRight: 0,
      marginBottom: 10,
    },
  },
  selectBigBoxManagement: {
    width: '30%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    '@media (max-width: 1280px)': {
      width: '100%',
      height: '35%',
      marginRight: 0,
      marginBottom: 10,
    },
  },
  titleText: {
    width: '8vh',
    fontSize: '2.5vh',
    fontWeight: 600,
  },
  iptModal: {
    height: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
        height: '100%',
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  selectBox: {
    width: '65%',
    height: '100%',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  tableBox: {
    height: '93%',
    width: '100%',
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    '@media (max-width: 1280px)': {
      justifyContent: 'start',
      height: '70%',
    },
  },
  tableManagementBox: {
    height: '93%',
    width: '100%',
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    '@media (max-width: 1280px)': {
      justifyContent: 'start',
      height: '80%',
    },
  },
  btn: {
    marginLeft: 15,
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    '&:hover': {
      background: '#214162',
      color: '#fff',
      cursor: 'pointer',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  collapseBox: {
    position: 'absolute',
    right: 0,
    top: 'calc( 100% + 10px )',
    '&>div>div': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  dateBox: {
    height: '100%',
    width: '75%',
    '&>div': {
      height: '100%',
      padding: 0,
      '&>div': {
        height: '100%',
        minWidth: 'auto !important',
        '&>div': {
          height: '100%',
          '&>input': {
            padding: '0 10px',
            fontSize: '1.8vh',
          },
          '&>fieldset': {
            top: '-4px',
            border: '2px solid #000 !important',
          },
        },
      },
    },
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
}))

interface WeeklySummaryBoxProps {
  urlName: any
}

const WeeklySummaryBox = (props: WeeklySummaryBoxProps) => {
  const { urlName } = props
  const { classes } = useStyles()
  const [loadingBoo, setLoadingBoo] = useState<any>(true)
  const [dateValue, setDateValue] = useState<any>('')
  const [arrObj, setArrObj] = useState<any>('')
  const [open, setOpen] = useState<any>(false)
  const [selectValue, setSelectValue] = useState('')
  const [arrExportList, setArrExportList] = useState<any>({})
  const [arrParkingLotList, setArrParkingLotList] = useState<any>([])
  const urlObj = getQueryParamsObj(window.location?.search)
  const navigate = useNavigate()

  const exportObj = (res: any) => {
    let arrObj: any = {}
    arrObj = {
      '!cols': [],
      '!fullref': `A1:F${(res?.length || 0) + 1}`,
      '!ref': `A1:F${(res?.length || 0) + 1}`,
      '!rows': [],
      A1: { t: 's', v: '小区' },
      B1: { t: 's', v: '账单名称' },
      C1: { t: 's', v: '账单类型' },
      D1: { t: 's', v: '人数' },
      E1: { t: 's', v: '账单金额' },
      F1: { t: 's', v: '截至日期' },
    }
    res?.forEach((item: any, i: number) => {
      arrObj[`A${i + 2}`] = { t: 's', v: item.name || '' }
      arrObj[`B${i + 2}`] = { t: 's', v: item.billName || '' }
      arrObj[`C${i + 2}`] = { t: 's', v: item.billType || '' }
      arrObj[`D${i + 2}`] = { t: 'n', v: item.ownerCount || 0 }
      arrObj[`E${i + 2}`] = { t: 'n', v: item.money || 0 }
      arrObj[`F${i + 2}`] = { t: 's', v: item.weekEnd || '' }
    })
    setArrExportList(arrObj)
  }

  const getList = (name: any, id: any, startDate: any, endDate: any) => {
    getWeeklySummary(selectValue || urlObj?.id, startDate, endDate)
      .then((res: any) => {
        const timeArr: any = []
        const newArr: any = []
        res?.map((item: any) => {
          timeArr.push(item.weekEnd)
        })
        Array.from(new Set(timeArr))?.map((item: any) => {
          let obj: any = {
            name: '',
            weekEnd: '',
            manage: {
              oldDebtMoney: 0,
              oldDebtOwnerCount: '',
              thenMoney: 0,
              thenOwnerCount: '',
              nextYearMoney: 0,
              nextYearOwnerCount: '',
            },
            shops: {
              oldDebtMoney: 0,
              oldDebtOwnerCount: '',
              thenMoney: 0,
              thenOwnerCount: '',
              nextYearMoney: 0,
              nextYearOwnerCount: '',
            },
            rent: {
              oldDebtMoney: 0,
              oldDebtOwnerCount: '',
              thenMoney: 0,
              thenOwnerCount: '',
              nextYearMoney: 0,
              nextYearOwnerCount: '',
            },
          }
          res?.map((items: any) => {
            if (item === items.weekEnd) {
              if (items.billName === '管理费') {
                if (items.billType === '当年') {
                  obj = {
                    ...obj,
                    name: items.name,
                    weekEnd: items.weekEnd,
                    manage: {
                      ...obj.manage,
                      thenMoney: items.money || 0,
                      thenOwnerCount: items.ownerCount,
                    },
                  }
                } else if (items.billType === '收旧') {
                  obj = {
                    ...obj,
                    name: items.name,
                    weekEnd: items.weekEnd,
                    manage: {
                      ...obj.manage,
                      oldDebtMoney: items.money || 0,
                      oldDebtOwnerCount: items.ownerCount,
                    },
                  }
                } else if (items.billType === '预收') {
                  obj = {
                    ...obj,
                    name: items.name,
                    weekEnd: items.weekEnd,
                    manage: {
                      ...obj.manage,
                      nextYearMoney: items.money || 0,
                      nextYearOwnerCount: items.ownerCount,
                    },
                  }
                }
              } else if (items.billName === '商铺管理费') {
                if (items.billType === '当年') {
                  obj = {
                    ...obj,
                    name: items.name,
                    weekEnd: items.weekEnd,
                    shops: {
                      ...obj.manage,
                      thenMoney: items.money || 0,
                      thenOwnerCount: items.ownerCount,
                    },
                  }
                } else if (items.billType === '收旧') {
                  obj = {
                    ...obj,
                    name: items.name,
                    weekEnd: items.weekEnd,
                    shops: {
                      ...obj.manage,
                      oldDebtMoney: items.money || 0,
                      oldDebtOwnerCount: items.ownerCount,
                    },
                  }
                } else if (items.billType === '预收') {
                  obj = {
                    ...obj,
                    name: items.name,
                    weekEnd: items.weekEnd,
                    shops: {
                      ...obj.manage,
                      nextYearMoney: items.money || 0,
                      nextYearOwnerCount: items.ownerCount,
                    },
                  }
                }
              } else if (items.billName === '租金') {
                if (items.billType === '当年') {
                  obj = {
                    ...obj,
                    name: items.name,
                    weekEnd: items.weekEnd,
                    rent: {
                      ...obj.manage,
                      thenMoney: items.money || 0,
                      thenOwnerCount: items.ownerCount,
                    },
                  }
                } else if (items.billType === '收旧') {
                  obj = {
                    ...obj,
                    name: items.name,
                    weekEnd: items.weekEnd,
                    rent: {
                      ...obj.manage,
                      oldDebtMoney: items.money || 0,
                      oldDebtOwnerCount: items.ownerCount,
                    },
                  }
                } else if (items.billType === '预收') {
                  obj = {
                    ...obj,
                    name: items.name,
                    weekEnd: items.weekEnd,
                    rent: {
                      ...obj.manage,
                      nextYearMoney: items.money || 0,
                      nextYearOwnerCount: items.ownerCount,
                    },
                  }
                }
              }
            }
          })
          newArr.push(obj)
        })
        exportObj(res)
        setLoadingBoo(false)
        setArrObj({
          name,
          arr: newArr,
        })
      })
      .catch(console.error)
  }

  const getParkingLotArr = (dateValues: any) => {
    getProjectList()
      .then((res: any) => {
        setArrParkingLotList(res)
        getList(
          res?.filter((item: any) => item.id === urlObj?.id)[0]?.name,
          res?.filter((item: any) => item.id === urlObj?.id)[0]?.id,
          dateValues[0],
          dateValues[1],
        )
      })
      .catch(console.error)
  }

  useEffect(() => {
    if (urlObj?.id) {
      setSelectValue(urlObj?.id || '')
    }
    if (urlObj?.date) {
      getParkingLotArr(urlObj?.date?.split('~') || ['', ''])
      setDateValue(urlObj?.date?.split('~') || ['', ''])
    }
  }, [])

  return (
    <div className={classes.root}>
      <div className={!urlName.includes('management') ? classes.title : classes.titleManagement}>
        <div className={classes.titleLeftBox}>
          {!urlName.includes('management') && (
            <div className={classes.selectBigBox}>
              <div className={classes.titleText}>小区：</div>
              <div className={classes.selectBox}>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={arrParkingLotList?.length > 0 ? selectValue : ''}
                    onChange={(event: any) => {
                      setSelectValue(event.target.value)
                    }}
                  >
                    {arrParkingLotList?.map((item: any, i: number) => {
                      return (
                        <MenuItem key={i} value={item.id}>
                          {`${item.name}-${item.id}`}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          <div className={!urlName.includes('management') ? classes.selectBigBox1 : classes.selectBigBoxManagement}>
            <div className={classes.titleText}>时间： </div>
            <div className={classes.dateBox}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['SingleInputDateRangeField']}>
                  <DateRangePicker
                    disableFuture
                    format="YYYY/MM/DD"
                    minDate={dayjs('2024-09-01')}
                    value={[dayjs(dateValue[0]), dayjs(dateValue[1])]}
                    onChange={(e: any) => {
                      const dateOne = e[0] && e[0] !== null ? dayjs(e[0]).format('YYYY-MM-DD') : ''
                      const dateTwo = e[1] && e[1] !== null ? dayjs(e[1]).format('YYYY-MM-DD') : ''
                      setDateValue([dateOne, dateTwo])
                    }}
                    slots={{ field: SingleInputDateRangeField }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <Button
            style={{ marginLeft: 0 }}
            onClick={() => {
              navigate(`?id=${selectValue}&date=${dateValue[0]}~${dateValue[1]}`)
              setLoadingBoo(true)
              getList(
                arrParkingLotList?.filter((res: any) => res.id === selectValue)[0]?.name,
                arrParkingLotList?.filter((item: any) => item.id === urlObj?.id)[0]?.id,
                dateValue[0],
                dateValue[1],
              )
            }}
            variant="contained"
            className={classes.btn}
          >
            查询
          </Button>
          <Button
            onClick={() => {
              setOpen(!open)
            }}
            variant="contained"
            className={classes.btn}
          >
            导出
          </Button>
          <Collapse className={classes.collapseBox} in={open} timeout="auto" unmountOnExit>
            <Button
              style={{ padding: '2px 10px', marginLeft: 0, marginBottom: 10 }}
              onClick={() => {
                if (arrObj !== '') {
                  exportExcel(
                    arrExportList,
                    `${arrObj?.name}管理费每周统计-数据-${moment.utc(new Date()).format('YYYY-MM-DD')}.xlsx`,
                  )
                }
              }}
              variant="contained"
              className={classes.btn}
            >
              数据导出
            </Button>
            <Button
              style={{ padding: '2px 10px', marginLeft: 0 }}
              onClick={() => {
                if (arrObj !== '') {
                  tabExportExcel(
                    document.getElementById('pdfTable'),
                    `${arrObj?.name}管理费每周统计-表格-${moment.utc(new Date()).format('YYYY-MM-DD')}.xlsx`,
                  )
                }
              }}
              variant="contained"
              className={classes.btn}
            >
              列表导出
            </Button>
          </Collapse>
        </div>
      </div>
      {loadingBoo ? (
        <div className={!urlName.includes('management') ? classes.tableBox : classes.tableManagementBox}>
          <LoadingBox />
        </div>
      ) : (
        <div className={!urlName.includes('management') ? classes.tableBox : classes.tableManagementBox}>
          <OneTableBox arrObj={arrObj} />
        </div>
      )}
    </div>
  )
}

export default WeeklySummaryBox
