import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import { getTransactionIdNum, updateInvoice } from '@/api'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

const useStyles = makeStyles()(() => ({
  checkBox: {
    margin: 0,
    '&>span:nth-of-type(1)': {
      padding: 0,
      paddingRight: 5,
      '&>svg': {
        width: '3vh',
        height: '3vh',
      },
    },
    '&>span:nth-of-type(2)': {
      color: '#000',
      fontSize: '1.8vh',
    },
  },
  textField: {
    maxWidth: 150,
    width: '100%',
    '@media (max-width: 1280px)': {
      maxWidth: 120,
      fontSize: '1.4vh',
    },
  },
  underline: {
    '&::before,&::after': {
      display: 'none',
    },
  },
  dateInput: {
    width: 'calc( 100% - 4px )',
    textAlign: 'center',
    color: '#000',
    fontSize: '1.8vh',
    padding: 0,
    border: '2px solid #000',
    borderRadius: 5,
    height: '100%',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
  btn: {
    background: '#214162',
    color: '#fff',
    fontSize: '2vh',
    padding: '2px 30px',
    height: '100%',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
  },
  smallBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    '&>div:nth-of-type(1)': {
      width: '7vh',
    },
    '&>div:nth-of-type(2)': {
      flex: 1,
    },
  },
  smallBox1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8,
    '&>div:nth-of-type(1)': {
      '&>div:nth-of-type(1)': {
        marginRight: 5,
      },
      display: 'flex',
    },
  },
  iptModal: {
    width: '100%',
    height: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '1.4vh',
      background: 'transparent',
      padding: '4px 8px',
      height: '100%',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  btnBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

interface ModelBoxProps {
  timeBoo: any
  payTime: any
  receiptNumber: any
  getOpen: (boo: any) => void
  transactionId: any
  getDateObj: (obj: any) => void
}

const ModelBox = (props: ModelBoxProps) => {
  const { classes } = useStyles()
  const { timeBoo, payTime, receiptNumber, transactionId, getOpen, getDateObj } = props
  const [dateValue, setDateValue] = useState('')
  const [transactionIdValue, setTransactionIdValue] = useState<any>('')
  const [transactionIdNum, setTransactionIdNum] = useState<any>('')
  const [stateStr, setStateStr] = useState(false)

  const getNum = (num: any) => {
    getTransactionIdNum(num)
      .then((res: any) => {
        setTransactionIdNum(res?.total && res?.total !== '' ? res?.total : 0)
      })
      .catch(console.error)
  }

  useEffect(() => {
    setStateStr(timeBoo)
  }, [timeBoo])

  useEffect(() => {
    setTransactionIdValue(transactionId)
  }, [transactionId])

  useEffect(() => {
    getNum(transactionId)
    setDateValue(payTime || '')
  }, [])

  return (
    <>
      <div>
        <div className={classes.smallBox}>
          <div>时间：</div>
          <div>
            <TextField
              id={`date-start-${receiptNumber}`}
              name={`date-start-${receiptNumber}`}
              type="date"
              value={dateValue || ''}
              className={classes.textField}
              onChange={(event: any) => {
                setDateValue(event.target.value)
              }}
              InputProps={{
                classes: {
                  input: classes.dateInput,
                  underline: classes.underline,
                },
              }}
            />
          </div>
        </div>
        <div className={classes.smallBox}>
          <div>流水号：</div>
          <div>
            <TextField
              id={`transactionId-start-${receiptNumber}`}
              name={`transactionId-start-${receiptNumber}`}
              value={transactionIdValue}
              className={classes.iptModal}
              onChange={(event: any) => {
                getNum(event.target.value)
                setTransactionIdValue(event.target.value)
              }}
            />
          </div>
        </div>
        <div className={classes.smallBox1}>
          <div>
            <div>流水号(金额):</div>
            <div>{Number(transactionIdNum)?.toFixed(2)}</div>
          </div>
        </div>
        <div className={classes.smallBox}>
          <div>付款：</div>
          <div>
            <FormGroup>
              <FormControlLabel
                className={classes.checkBox}
                control={
                  <Checkbox
                    checked={stateStr}
                    onChange={() => {
                      setStateStr(!stateStr)
                    }}
                    color="default"
                  />
                }
                label=""
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <div className={classes.btnBox}>
        <Button
          style={{ marginRight: 10 }}
          onClick={() => {
            getOpen(false)
          }}
          variant="contained"
          className={classes.btn}
        >
          取消
        </Button>
        <Button
          onClick={() => {
            updateInvoice(receiptNumber, stateStr ? '已收款' : '未收款', dateValue, transactionIdValue).catch(
              console.error,
            )
            getDateObj({
              receiptNumber,
              date: dateValue,
              transactionId: transactionIdValue,
              status: stateStr ? '已收款' : '未收款',
            })
            getOpen(false)
          }}
          variant="contained"
          className={classes.btn}
        >
          保存
        </Button>
      </div>
    </>
  )
}

export default ModelBox
