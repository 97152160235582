import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import HeaderBox from '@/components/adminBox/headerBox'
import classNames from 'classnames'
import EditBox from '@/components/adminBox/editBox'
import CarListBox from '@/components/adminBox/carListBox'
import ParkingLotFinance from 'src/components/adminBox/parkingLotFinance'
import { useParams, Link, useNavigate } from 'react-router-dom'
import PropertyDetails from '@/components/adminBox/propertyDetails'
import MenuIcon from '@mui/icons-material/Menu'
import moment from 'moment/moment'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { getChildHrefStr, getProjectList, getQueryParamsObj } from '@/api'
import AuditListBox from '@/components/auditListBox'
import AdminPropertyDetails from '@/components/adminBox/adminPropertyDetails'
import CollapseBox from '@/components/adminBox/collapseBox'
import InvoicingListBox from '@/components/adminBox/invoicingListBox'
import ChannelEventsBox from '@/components/adminBox/channelEventsBox'
import PublicWelfareBox from '@/components/adminBox/publicWelfareBox'
import TemporaryVehicle from '@/components/adminBox/temporaryVehicle'
import ReportManagementListBox from '@/components/adminBox/reportManagementListBox'
import OtherFunctionsBox from '@/components/adminBox/otherFunctions'
import UnbilledDataBox from '@/components/adminBox/unbilledDataBox'
import NavigationImg from '@/image/navigationImg.png'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import DriveEtaIcon from '@mui/icons-material/DriveEta'
import ReceiptIcon from '@mui/icons-material/Receipt'
import RvHookupIcon from '@mui/icons-material/RvHookup'
import SummarizeIcon from '@mui/icons-material/Summarize'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import CardTravelIcon from '@mui/icons-material/CardTravel'
import HandshakeIcon from '@mui/icons-material/Handshake'
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import DayReportBox from '@/components/adminBox/dayReportBox'
import MonthlyReportBox from '@/components/adminBox/monthlyReportBox'
import MonthlyReportTwoBox from '@/components/adminBox/monthlyReportTwoBox'
import UnconfirmedBillsBox from '@/components/adminBox/unconfirmedBillsBox'
import WeeklySummaryBox from '@/components/adminBox/weeklySummaryBox'
// import DailyChargesBox from '@/components/adminBox/dailyCharges'

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '1.4vh',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100vh',
    background: '#eee',
    display: 'flex',
    color: '#fff',
    position: 'relative',
  },
  contentBox: {
    height: '100vh',
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1280px)': {
      width: '100% !important',
    },
  },
  contentLeftBox: {
    width: '10%',
    height: '100%',
    background: '#15283c',
    color: '#ccc',
    boxShadow: '0px -5px 15px -2px #000',
    backgroundImage: `url(${NavigationImg})`,
    '@media (max-width: 1280px)': {
      top: '8%',
      width: 200,
      position: 'absolute',
      zIndex: 3,
      height: '92vh',
      overflow: 'auto',
    },
  },
  leftBoxTitle: {
    height: '6%',
    color: '#fff',
    fontSize: '2vh',
    boxShadow: '0px 4px 13px -5px #000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>svg': {
      fontSize: '4vh',
    },
    '@media (max-width: 1280px)': {
      display: 'none',
    },
  },
  leftBoxList: {
    height: '94%',
  },
  contentRightBox: {
    width: '100%',
    height: '94%',
    '@media (max-width: 1280px)': {
      width: '100%',
    },
  },
  btnBox: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none',
    position: 'relative',
    '&>svg': {
      marginRight: 5,
    },
    '@media (min-width: 1281px)': {
      '&:hover': {
        background: '#214162',
        color: '#fff',
        cursor: 'pointer',
      },
    },
  },
  btnBoxBg: {
    background: '#214162',
    color: '#fff !important',
  },
  menuIcon: {
    position: 'absolute',
    width: '4vh',
    height: '4vh',
    top: '1vh',
    zIndex: 2,
    left: 10,
  },
  stretchBox: {
    width: 150,
    position: 'absolute',
    background: '#15283c',
    color: '#ccc',
    boxShadow: '0px -5px 15px -2px #000',
    backgroundImage: `url(${NavigationImg})`,
    zIndex: 5,
    right: -150,
    top: 0,
  },
  itemTitle: {
    whiteSpace: 'nowrap',
  },
}))

interface AdminBoxProps {
  configJson: any
  parkingJson: any
}
const AdminBox = (props: AdminBoxProps) => {
  const { configJson, parkingJson } = props
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [headerName, setHeaderName] = useState<any>('')
  const [communityList, setCommunityList] = useState<any>([])
  const [titleName, setTitleName] = useState('')
  const [open, setOpen] = useState<any>(false)
  const [stretch, setStretch] = useState<any>(false)
  const [idNum, setIdNum] = useState<any>('0')
  const { urlId } = useParams()
  const divRef: any = useRef()
  const [divWidth, setDisWidth] = useState(451)
  const urlName = window.location.pathname.split(`${urlId}`)[0]
  const urlListName = window.location.pathname.split(`${urlId}`)[0]?.split('/')[1]
  const urlObj = getQueryParamsObj(window.location?.search)

  const [listArr, setListArr] = useState<any>([
    {
      id: '1',
      name: '车辆管理',
      load: 'admin-management-security',
      phone: true,
      pc: true,
      open: '',
      icon: <DriveEtaIcon />,
    },
    {
      id: '14',
      name: '通道事件',
      load: '',
      phone: true,
      pc: true,
      open: '',
      icon: <></>,
    },
    {
      id: '2',
      name: '编辑地图',
      load: '',
      phone: false,
      pc: true,
      open: '',
      icon: <></>,
    },
    {
      id: '3',
      name: '账单审计列表',
      load: 'admin-management-security',
      phone: true,
      pc: true,
      open: '',
      icon: <ReceiptIcon />,
    },
    {
      id: '21',
      name: '临时车辆',
      load: 'admin-management',
      phone: true,
      pc: true,
      open: '',
      icon: <RvHookupIcon />,
    },
    // Number(moment.utc(new Date()).format('YYYY')) > 2024 && {
    //   id: '16',
    //   name: `${moment.utc(new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000)).format('YYYY')}-应收考核`,
    //   load: 'admin',
    //   phone: true,
    //   pc: false,
    //   open: false,
    //   year: moment.utc(new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000)).format('YYYY'),
    //   icon: <SummarizeIcon />,
    // },
    {
      id: '4',
      name: `${
        Number(moment.utc(new Date()).format('MM')) < 4
          ? moment.utc(new Date()).subtract(1, 'year').format('YYYY')
          : moment.utc(new Date()).format('YYYY')
      }-应收考核`,
      load: 'admin-management',
      phone: true,
      pc: true,
      open: false,
      year:
        Number(moment.utc(new Date()).format('MM')) < 4
          ? moment.utc(new Date()).subtract(1, 'year').format('YYYY')
          : moment.utc(new Date()).format('YYYY'),
      icon: <SummarizeIcon />,
    },
    {
      id: '26',
      name: '管理费月报',
      load: 'admin',
      phone: true,
      pc: true,
      open: '',
      icon: <AssessmentIcon />,
    },
    {
      id: '27',
      name: '租金月报',
      load: 'admin',
      phone: true,
      pc: true,
      open: '',
      icon: <AssessmentIcon />,
    },
    {
      id: '28',
      name: '商铺管理费月报',
      load: 'admin',
      phone: true,
      pc: true,
      open: '',
      icon: <AssessmentIcon />,
    },
    {
      id: '9',
      name: '车场报表',
      load: 'admin-management',
      phone: true,
      pc: true,
      open: false,
      icon: <AccountBalanceWalletIcon />,
    },
    {
      id: '12',
      name: '财务数据统计',
      load: 'admin-management',
      phone: true,
      pc: true,
      open: false,
      icon: <CardTravelIcon />,
    },
    {
      id: '30',
      name: '管理费每周统计',
      load: 'admin-management',
      phone: true,
      pc: true,
      open: '',
      icon: <HandshakeIcon />,
    },
    // {
    //   id: '31',
    //   name: '日常收费',
    //   load: 'admin',
    //   phone: true,
    //   pc: true,
    //   open: '',
    //   icon: <HandshakeIcon />,
    // },
    {
      id: '15',
      name: '公益支出',
      load: 'admin',
      phone: true,
      pc: true,
      open: '',
      icon: <HandshakeIcon />,
    },
    {
      id: '23',
      name: '其他功能',
      load: 'admin',
      phone: true,
      pc: true,
      open: '',
      icon: <CalendarViewDayIcon />,
    },
  ])

  const typeArr: any = [
    {
      id: '5',
      name: '管理费',
      titleName: '应收考核-管理费',
    },
    {
      id: '6',
      name: '租金',
      titleName: '应收考核-租金',
    },
    {
      id: '7',
      name: '商铺管理费',
      titleName: '应收考核-商铺',
    },
    {
      id: '8',
      name: '停车费',
      titleName: '应收考核-停车费',
    },
  ]

  const typeTwoArr: any = [
    {
      id: '17',
      name: '管理费',
      titleName: '应收考核-管理费',
    },
    {
      id: '18',
      name: '租金',
      titleName: '应收考核-租金',
    },
    {
      id: '19',
      name: '商铺管理费',
      titleName: '应收考核-商铺',
    },
    {
      id: '20',
      name: '停车费',
      titleName: '应收考核-停车费',
    },
  ]

  const reportArr: any = [
    {
      id: '10',
      name: '临时车日报',
      titleName: '临时车-日报',
    },
    {
      id: '11',
      name: '车场月报',
      titleName: '车场-月报',
    },
  ]

  const reportManagementArr: any = [
    {
      id: '22',
      name: '车场报表',
      titleName: '车场-报表',
    },
  ]

  const invoicingArr: any = [
    {
      id: '13',
      name: '开票数据列表',
      titleName: '开票-列表',
    },
    {
      id: '24',
      name: '分摊未开票票据',
      titleName: '未开票-列表',
    },
    {
      id: '29',
      name: '未确认收款票据',
      titleName: '未确认-票据',
    },
  ]

  const invoicingManagementArr: any = [
    {
      id: '29',
      name: '未确认收款票据',
      titleName: '未确认-列表',
    },
  ]

  const getStretch = (boo: any) => {
    if (boo) {
      const newArr: any[] = []
      listArr?.map((items: any) => {
        if (items?.open !== '') {
          newArr.push({ ...items, open: false })
        } else {
          newArr.push({ ...items, open: '' })
        }
      })
      setListArr([...newArr])
    }
    setStretch(boo)
  }

  const getContentArr = (id: any) => {
    let newArr: any
    switch (id) {
      case '4':
        newArr = [...typeArr]
        break
      case '9':
        if (urlName?.includes('management')) {
          newArr = [...reportManagementArr]
        } else {
          newArr = [...reportArr]
        }
        break
      case '12':
        if (urlName?.includes('management')) {
          newArr = [...invoicingManagementArr]
        } else {
          newArr = [...invoicingArr]
        }
        break
      case '16':
        newArr = [...typeTwoArr]
        break
      default:
        newArr = []
        break
    }
    return newArr
  }

  const getOpenValue = () => {
    setOpen(false)
  }

  const getDiv = () => {
    let divBox: any
    switch (idNum) {
      case '1':
        divBox = <CarListBox urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        break
      case '2':
        divBox = <EditBox configJson={configJson} />
        break
      case '3':
        if (urlName?.includes('management') || urlName?.includes('security')) {
          divBox = <AuditListBox />
        } else {
          divBox = <ParkingLotFinance urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        }
        break
      case '5':
        if (urlName?.includes('management')) {
          divBox = <PropertyDetails urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        } else {
          divBox = <AdminPropertyDetails />
        }
        break
      case '6':
        if (urlName?.includes('management')) {
          divBox = <PropertyDetails urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        } else {
          divBox = <AdminPropertyDetails />
        }
        break
      case '7':
        if (urlName?.includes('management')) {
          divBox = <PropertyDetails urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        } else {
          divBox = <AdminPropertyDetails />
        }
        break
      case '8':
        if (urlName?.includes('management')) {
          divBox = <PropertyDetails urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        } else {
          divBox = <AdminPropertyDetails />
        }
        break
      case '10':
        divBox = <DayReportBox urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        break
      case '11':
        divBox = <MonthlyReportBox urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        break
      case '26':
        divBox = <MonthlyReportTwoBox urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        break
      case '27':
        divBox = <MonthlyReportTwoBox urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        break
      case '28':
        divBox = <MonthlyReportTwoBox urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        break
      case '13':
        divBox = <InvoicingListBox divWidth={divRef?.current?.clientWidth} />
        break
      case '14':
        divBox = <ChannelEventsBox urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        break
      case '15':
        divBox = <PublicWelfareBox />
        break
      case '17':
        if (urlName?.includes('management')) {
          divBox = <PropertyDetails urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        } else {
          divBox = <AdminPropertyDetails />
        }
        break
      case '18':
        if (urlName?.includes('management')) {
          divBox = <PropertyDetails urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        } else {
          divBox = <AdminPropertyDetails />
        }
        break
      case '19':
        if (urlName?.includes('management')) {
          divBox = <PropertyDetails urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        } else {
          divBox = <AdminPropertyDetails />
        }
        break
      case '20':
        if (urlName?.includes('management')) {
          divBox = <PropertyDetails urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        } else {
          divBox = <AdminPropertyDetails />
        }
        break
      case '21':
        divBox = <TemporaryVehicle parkingJson={parkingJson} urlName={urlName} />
        break
      case '22':
        divBox = <ReportManagementListBox divWidth={divRef?.current?.clientWidth} />
        break
      case '23':
        divBox = <OtherFunctionsBox divWidth={divRef?.current?.clientWidth} />
        break
      case '24':
        divBox = <UnbilledDataBox divWidth={divRef?.current?.clientWidth} />
        break
      case '29':
        divBox = <UnconfirmedBillsBox urlName={urlName} divWidth={divRef?.current?.clientWidth} />
        break
      case '30':
        divBox = <WeeklySummaryBox urlName={urlName} />
        break
      // case '31':
      //   divBox = <DailyChargesBox />
      // break
      default:
        divBox = <></>
        break
    }
    return divBox
  }

  const getCommunityArr = () => {
    getProjectList()
      .then((res: any) => {
        setCommunityList(res)
      })
      .catch(console.error)
  }

  const getHrefStr = (item: any) => {
    let newHrefStr
    switch (item.id) {
      case '1':
        newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
          item.id
        }?id=${urlObj?.id || 101}`
        break
      case '3':
        if (urlName.includes('management')) {
          newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
            item.id
          }?id=${urlObj?.id || 101}&date=${moment
            .utc(new Date(new Date().getTime() - 24 * 60 * 60 * 1000))
            .format('YYYY-MM-DD')}`
        } else {
          newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
            item.id
          }?id=${urlObj?.id || 101}&date=${moment
            .utc(new Date(new Date().getTime() - 24 * 60 * 60 * 1000))
            .format('YYYY-MM-DD')}`
        }
        break
      case '14':
        newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
          item.id
        }?id=${urlObj?.id || 101}&date=${moment.utc(new Date()).format('YYYY-MM-DD')}T00:00:00~${moment
          .utc(new Date())
          .format('YYYY-MM-DD')}T23:59:59`
        break
      case '26':
        newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
          item.id
        }?id=${urlObj?.id || 101}&date=${moment.utc(new Date()).format('YYYY')}&typeValue=${item.id}&newId=all`
        break
      case '27':
        newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
          item.id
        }?id=${urlObj?.id || 101}&date=${moment.utc(new Date()).format('YYYY')}&typeValue=${item.id}&newId=all`
        break
      case '28':
        newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
          item.id
        }?id=${urlObj?.id || 101}&date=${moment.utc(new Date()).format('YYYY')}&typeValue=${item.id}&newId=all`
        break
      case '30':
        newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
          item.id
        }?id=${urlObj?.id || 101}&date=2024-09-01~${moment.utc(new Date()).format('YYYY-MM-DD')}`
        break
      default:
        newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
          item.id
        }?id=${urlObj?.id || 101}`
        break
    }
    return newHrefStr
  }

  useEffect(() => {
    // @ts-ignore
    setDisWidth(divRef?.current?.clientWidth)
    getCommunityArr()
  }, [])

  useEffect(() => {
    if (urlId) {
      setIdNum(urlId)
      if (urlName?.includes('admin')) {
        if (urlId === '5' || urlId === '6' || urlId === '7' || urlId === '8') {
          document.title = `${listArr?.filter((res: any) => res.id === '4')[0]?.year}-${
            typeArr?.filter((res: any) => res.id === urlId)[0]?.titleName
          }`
          setHeaderName(
            `${listArr?.filter((res: any) => res.id === '4')[0]?.year}-${
              typeArr?.filter((res: any) => res.id === urlId)[0]?.titleName
            }`,
          )
        } else if (urlId === '10' || urlId === '11') {
          document.title = `${reportArr?.filter((res: any) => res.id === urlId)[0]?.titleName}`
          setHeaderName(`${reportArr?.filter((res: any) => res.id === urlId)[0]?.titleName}`)
        } else if (urlId === '13' || urlId === '24' || urlId === '29') {
          document.title = `${invoicingArr?.filter((res: any) => res.id === urlId)[0]?.titleName}`
          setHeaderName(`${invoicingArr?.filter((res: any) => res.id === urlId)[0]?.titleName}`)
        } else if (urlId === '17' || urlId === '18' || urlId === '19' || urlId === '20') {
          document.title = `${listArr?.filter((res: any) => res.id === '16')[0]?.year}-${
            typeTwoArr?.filter((res: any) => res.id === urlId)[0]?.titleName
          }`
          setHeaderName(
            `${listArr?.filter((res: any) => res.id === '16')[0]?.year}-${
              typeTwoArr?.filter((res: any) => res.id === urlId)[0]?.titleName
            }`,
          )
        } else if (urlId === '22') {
          document.title = `${reportManagementArr?.filter((res: any) => res.id === urlId)[0]?.titleName}`
          setHeaderName(`${reportManagementArr?.filter((res: any) => res.id === urlId)[0]?.titleName}`)
        } else {
          document.title = `${listArr?.filter((res: any) => res.id === urlId)[0]?.name}`
          setHeaderName(`${listArr?.filter((res: any) => res.id === urlId)[0]?.name}`)
        }
      } else {
        if (urlId === '5' || urlId === '6' || urlId === '7' || urlId === '8') {
          document.title = `${titleName}-${listArr?.filter((res: any) => res.id === '4')[0]?.year}-${
            typeArr?.filter((res: any) => res.id === urlId)[0]?.titleName
          }`
          setHeaderName(
            `${titleName}-${listArr?.filter((res: any) => res.id === '4')[0]?.year}-${
              typeArr?.filter((res: any) => res.id === urlId)[0]?.titleName?.split('-')[1]
            }`,
          )
        } else if (urlId === '10' || urlId === '11') {
          document.title = `${titleName}-${reportArr?.filter((res: any) => res.id === urlId)[0]?.titleName}`
          setHeaderName(`${titleName}-${reportArr?.filter((res: any) => res.id === urlId)[0]?.titleName}`)
        } else if (urlId === '13' || urlId === '24' || urlId === '29') {
          document.title = `${titleName}-${invoicingArr?.filter((res: any) => res.id === urlId)[0]?.titleName}`
          setHeaderName(`${titleName}-${invoicingArr?.filter((res: any) => res.id === urlId)[0]?.titleName}`)
        } else if (urlId === '17' || urlId === '18' || urlId === '19' || urlId === '20') {
          document.title = `${titleName}-${listArr?.filter((res: any) => res.id === '16')[0]?.year}-${
            typeTwoArr?.filter((res: any) => res.id === urlId)[0]?.titleName
          }`
          setHeaderName(
            `${titleName}-${listArr?.filter((res: any) => res.id === '16')[0]?.year}-${
              typeTwoArr?.filter((res: any) => res.id === urlId)[0]?.titleName?.split('-')[1]
            }`,
          )
        } else if (urlId === '22') {
          document.title = `${titleName}-${reportManagementArr?.filter((res: any) => res.id === urlId)[0]?.titleName}`
          setHeaderName(`${titleName}-${reportManagementArr?.filter((res: any) => res.id === urlId)[0]?.titleName}`)
        } else {
          document.title = `${titleName}-${listArr?.filter((res: any) => res.id === urlId)[0]?.name}`
          setHeaderName(`${titleName}-${listArr?.filter((res: any) => res.id === urlId)[0]?.name}`)
        }
      }
    }
  }, [urlId, titleName])

  useEffect(() => {
    if (urlId) {
      setIdNum(urlId)
      const newArr: any[] = []
      if (urlId === '5' || urlId === '6' || urlId === '7' || urlId === '8') {
        listArr?.map((items: any) => {
          if (items.id === '4') {
            newArr.push({ ...items, open: true })
          } else {
            newArr.push(items)
          }
        })
        setListArr([...newArr])
      } else if (urlId === '10' || urlId === '11') {
        listArr?.map((items: any) => {
          if (items.id === '9') {
            newArr.push({ ...items, open: true })
          } else {
            newArr.push(items)
          }
        })
        setListArr([...newArr])
      } else if (urlId === '13' || urlId === '24' || urlId === '29') {
        listArr?.map((items: any) => {
          if (items.id === '12') {
            newArr.push({ ...items, open: true })
          } else {
            newArr.push(items)
          }
        })
        setListArr([...newArr])
      } else if (urlId === '17' || urlId === '18' || urlId === '19' || urlId === '20') {
        listArr?.map((items: any) => {
          if (items.id === '16') {
            newArr.push({ ...items, open: true })
          } else {
            newArr.push(items)
          }
        })
        setListArr([...newArr])
      } else {
        listArr?.map((items: any) => {
          if (items.id === '4' || items.id === '9' || items.id === '12' || items.id === '16') {
            newArr.push({ ...items, open: false })
          } else {
            newArr.push(items)
          }
        })
        setListArr([...newArr])
      }
    }
  }, [])

  useEffect(() => {
    if (urlObj?.id && communityList?.length > 0) {
      setTitleName(communityList?.filter((res: any) => res.id === urlObj?.id)[0]?.name)
    }
  }, [urlObj?.id, communityList])

  return (
    <div ref={divRef} className={classes.root}>
      {divWidth <= 1280 && (
        <MenuIcon
          onClick={() => {
            setOpen(!open)
          }}
          className={classes.menuIcon}
        />
      )}
      <div
        style={divWidth <= 1280 ? { display: open ? 'inline-block' : 'none' } : { width: stretch ? '3%' : '10%' }}
        className={classes.contentLeftBox}
      >
        <div className={classes.leftBoxTitle}>
          <SupervisorAccountIcon />
          {!stretch && <div>数据管理</div>}
        </div>
        <div id="leftBoxList" style={{ overflow: !stretch ? 'auto' : 'visible' }} className={classes.leftBoxList}>
          {listArr?.map((item: any, i: any) => {
            return (
              item.load?.includes(urlListName) && (
                <React.Fragment key={i}>
                  {item.open !== '' ? (
                    <div
                      onClick={() => {
                        const newArr: any[] = []
                        if (stretch) {
                          listArr?.map((items: any) => {
                            if (items.id === item.id) {
                              newArr.push({ ...items, open: !items?.open })
                            } else {
                              if (items?.open !== '') {
                                newArr.push({ ...items, open: false })
                              } else {
                                newArr.push(items)
                              }
                            }
                          })
                        } else {
                          listArr?.map((items: any) => {
                            if (items.id === item.id) {
                              newArr.push({ ...items, open: !items?.open })
                            } else {
                              newArr.push(items)
                            }
                          })
                        }
                        setListArr([...newArr])
                      }}
                      style={{ justifyContent: stretch ? 'center' : '' }}
                      className={
                        idNum === item.id
                          ? classNames([classes.btnBox, classes.btnBoxBg])
                          : classNames([classes.btnBox])
                      }
                    >
                      {!stretch ? (
                        item.icon
                      ) : (
                        <BootstrapTooltip title={item.name} placement="right">
                          {item.icon}
                        </BootstrapTooltip>
                      )}
                      {!stretch && (
                        <>
                          <div className={classes.itemTitle}>{item.name}</div>
                          <div style={{ display: 'flex', position: 'absolute', right: 10 }}>
                            {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </div>
                        </>
                      )}
                      {stretch && item.open && (
                        <div className={classes.stretchBox}>
                          {getContentArr(item.id)?.map((items: any, j: any) => {
                            return (
                              <div
                                onClick={() => {
                                  navigate(getChildHrefStr(items, item.id, urlName, urlObj))
                                }}
                                className={
                                  idNum === items.id
                                    ? classNames([classes.btnBox, classes.btnBoxBg])
                                    : classNames([classes.btnBox])
                                }
                                key={j}
                              >
                                {items.name}
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  ) : (
                    <Link
                      to={getHrefStr(item)}
                      onClick={() => {
                        if (divWidth <= 1280) {
                          open && getOpenValue()
                        }
                      }}
                      key={item.id}
                      style={{ justifyContent: stretch ? 'center' : '' }}
                      className={
                        idNum === item.id
                          ? classNames([classes.btnBox, classes.btnBoxBg])
                          : classNames([classes.btnBox])
                      }
                    >
                      {!stretch ? (
                        item.icon
                      ) : (
                        <BootstrapTooltip title={item.name} placement="right">
                          {item.icon}
                        </BootstrapTooltip>
                      )}
                      {!stretch && <div className={classes.itemTitle}>{item.name}</div>}
                    </Link>
                  )}
                  {item.open !== '' && !stretch && (
                    <CollapseBox
                      openBoo={item.open}
                      list={getContentArr(item.id)}
                      idNum={idNum}
                      id={item.id}
                      titleName={titleName}
                      getOpenValue={getOpenValue}
                      open={open}
                      divWidth={divWidth}
                    />
                  )}
                </React.Fragment>
              )
            )
          })}
        </div>
      </div>
      <div style={{ width: stretch ? '97%' : '90%' }} className={classes.contentBox}>
        <HeaderBox stretch={stretch} getStretch={getStretch} divWidth={divWidth} headerName={headerName} />
        <div className={classes.contentRightBox}>{getDiv()}</div>
      </div>
    </div>
  )
}

export default AdminBox
