import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import Button from '@mui/material/Button'
import TableCell from '@mui/material/TableCell'
import { exportExcel, getInvoicing, getQueryParamsObj } from '@/api'
import { useNavigate } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import dayjs from 'dayjs'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { FormControl, MenuItem, Select } from '@mui/material'
import InvoicingCollapseBox from '@/components/adminBox/invoicingListBox/invoicingCollapseBox'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import classNames from 'classnames'
import moment from 'moment'
import { config } from '@/Constants'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import LoadingBox from '@/components/baseMap/loadingBox'

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '1.4vh',
  },
}))

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0.5vh',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.5vh',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '1.7vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledSumTableCell = withStyles(TableCell, (theme) => ({
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '2vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
  },
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '34px',
    '&>svg': {
      width: '3vh',
      height: '3vh',
      '@media (max-width: 1280px)': {
        width: '2vh',
        height: '2vh',
      },
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  thBox1: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    lineHeight: '34px',
    '&:hover': {
      cursor: 'pointer',
    },
    '&>svg': {
      width: '3vh',
      height: '3vh',
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  thFont: {
    fontWeight: 600,
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '12.5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&>div:nth-of-type(2)': {
      height: '46%',
      width: '100%',
      '@media (max-width: 1280px)': {
        height: 'auto',
      },
    },
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '40%',
    },
  },
  titleLeftBox: {
    display: 'flex',
    color: '#000',
    height: '46%',
    width: '100%',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '80%',
      width: '90%',
      justifyContent: 'space-between',
    },
  },
  selectBigBox: {
    width: '15%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1280px)': {
      width: '100% !important',
      height: '15%',
    },
  },
  titleText: {
    width: '8vh',
    fontSize: '2.5vh',
    fontWeight: 600,
    '@media (max-width: 1280px)': {
      width: '10vh !important',
    },
  },
  titleTextHover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableBox: {
    overflow: 'auto',
    minWidth: 1200,
    position: 'relative',
  },
  btn: {
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  btn1: {
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: 40,
    borderRadius: 5,
    border: 0,
    width: 115,
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  thBoxHover: {
    color: '#4169e1',
    borderBottom: '2px solid #4169e1',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dateBox: {
    height: '100%',
    width: '70%',
    '&>div': {
      height: '100%',
      padding: 0,
      '&>div': {
        height: '100%',
        minWidth: 'auto !important',
        '&>div': {
          height: '100%',
          '&>input': {
            padding: '0 10px',
          },
          '&>fieldset': {
            top: '-4px',
            border: '2px solid #000 !important',
          },
        },
      },
    },
    '&>input': {
      height: '100%',
      width: '100%',
      fontSize: '1.8vh',
      textAlign: 'center',
      color: '#000',
      padding: 0,
      border: '2px solid #000',
      borderRadius: 5,
      background: 'transparent',
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      '&::before,&::after': {
        display: 'none',
      },
    },
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  totalBox: {
    display: 'flex',
    width: '100%',
    background: '#fff',
    borderRadius: ' 0 0 5px 5px',
    color: '#000',
    minWidth: 1200,
    '&>div': {
      width: '9%',
      padding: '1vh 0.5vh',
      fontSize: '2vh',
      lineHeight: 1.5,
      fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
      fontWeight: 600,
      textAlign: 'left',
      '@media (max-width: 1280px)': {
        fontSize: '1.6vh',
      },
      '&:nth-of-type(2)': {
        width: '12%',
      },
      '&:nth-of-type(3)': {
        width: '7%',
      },
      '&:nth-of-type(4)': {
        width: '10%',
      },
      '&:nth-of-type(5)': {
        width: '10%',
      },
      '&:nth-of-type(6)': {
        width: '14%',
      },
      '&:nth-of-type(7)': {
        width: '5.5%',
      },
      '&:nth-of-type(8)': {
        width: '11%',
      },
      '&:nth-of-type(9)': {
        width: '8.5%',
      },
      '&:nth-of-type(10)': {
        width: '6.5%',
        textAlign: 'right',
      },
      '&:nth-of-type(11)': {
        width: '6.5%',
        textAlign: 'right',
      },
    },
  },
  sumBox: {
    fontSize: '1.8vh',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
  selectBox: {
    width: '60%',
    height: '100%',
    position: 'relative',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  iptModal: {
    height: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  iptModalDelete: {
    '&>div>input': {
      width: '88% !important',
    },
  },
  iphoneBox: {
    height: '87.5%',
    '@media (max-width: 1280px)': {
      height: '60%',
      overflow: 'auto',
    },
  },
  modalBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalSmallBox: {
    position: 'relative',
    width: '15%',
    height: '15%',
    background: '#fff',
    padding: 10,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&>form': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'end',
    },
  },
  iptBox: {
    color: '#000',
    border: '2px solid #000',
    fontSize: '2vh',
    background: 'transparent',
    padding: '6px 10px',
    borderRadius: 5,
    width: '100%',
    height: 40,
  },
  iframeBox: {
    position: 'absolute',
    border: 0,
    height: 42,
    top: 50,
    width: '50%',
  },
  iconBox: {
    width: '3vh',
    height: '3vh',
    position: 'absolute',
    right: 4,
    top: 'calc( 50% - 1.5vh )',
    color: '#aaa',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  loadingBox: {
    margin: '20px auto',
    '@media (max-width: 1280px)': {
      margin: '20px 15%',
      display: 'flex',
      justifyContent: 'start',
    },
  },
}))

interface InvoicingListBoxProps {
  divWidth: any
}

let timer: NodeJS.Timeout
const InvoicingListBox = (props: InvoicingListBoxProps) => {
  const { divWidth } = props
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [timeBoo, setTimeBoo] = useState<any>(true)
  const [paddingBoo, setPaddingBoo] = useState(false)
  const [loadBoo, setLoadBoo] = useState<any>(true)
  const [ownerNameValue, setOwnerNameValue] = useState<any>('')
  const [buyerValue, setBuyerValue] = useState<any>('')
  const [transactionId, setTransactionId] = useState<any>('')
  const [amountValue, setAmountValue] = useState<any>('')
  const [selectValue, setSelectValue] = useState('现金')
  const [modalOpen, setModalOpen] = useState<any>(false)
  const [arrList, setArrList] = useState<any>([])
  const [arrNameList, setArrNameList] = useState<any>([])
  const [allTotalObj, setAllTotalObj] = useState<any>({})
  const [arrExportList, setArrExportList] = useState<any>({})
  const dateObj = getQueryParamsObj(window.location?.search)
  const [dateValue, setDateValue] = useState<any>('')
  const [totalObj, setTotalObj] = useState<any>({
    amount: 0,
    tax: 0,
  })

  const tableBigBox = document.getElementById('tableBigBox')
  const totalBox = document.getElementById('totalBox')
  const tableBox = document.getElementById('tableBox')

  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => {
    setModalOpen(false)
  }

  const getPaddingBoo = () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      if (tableBigBox?.clientHeight && tableBox?.clientHeight && arrList?.length > 0) {
        if (tableBox?.clientHeight <= tableBigBox?.clientHeight) {
          !paddingBoo && setPaddingBoo(true)
        } else {
          paddingBoo && setPaddingBoo(false)
        }
      }
    }, 350)
  }

  useEffect(() => {
    if (tableBigBox?.clientHeight && tableBox?.clientHeight && arrList?.length > 0) {
      if (tableBox?.clientHeight <= tableBigBox?.clientHeight) {
        !paddingBoo && setPaddingBoo(true)
      } else {
        paddingBoo && setPaddingBoo(false)
      }
    }
  }, [tableBigBox?.clientHeight, tableBox?.clientHeight, arrList])

  const headerArr = [
    {
      key: 0,
      name: '小区名',
      width: '9%',
    },
    {
      key: 1,
      name: '收费项目',
      width: '12%',
    },
    {
      key: 2,
      name: '日期',
      width: '7%',
    },
    {
      key: 3,
      name: '小票',
      width: '10%',
    },
    {
      key: 4,
      name: '发票',
      width: '10%',
    },
    {
      key: 5,
      name: '购买方',
      width: '14%',
    },
    {
      key: 6,
      name: '业主',
      width: '5.5%',
    },
    {
      key: 7,
      name: '收款确认',
      width: '11%',
    },
    {
      key: 8,
      name: '备注',
      width: '8.5%',
    },
    {
      key: 9,
      name: '金额',
      width: '6.5%',
    },
    {
      key: 10,
      name: '税费',
      width: '6.5%',
    },
  ]

  const selectItemList = [
    // {
    //   id: '0',
    //   name: '全部',
    //   receiptMethod: '现金,支付宝,微信,银联支付,转账',
    // },
    {
      id: '1',
      name: '现金',
      receiptMethod: '现金',
    },
    {
      id: '2',
      name: '电子支付',
      receiptMethod: '支付宝,微信',
    },
    {
      id: '3',
      name: '转账',
      receiptMethod: '转账',
    },
    {
      id: '4',
      name: '银联支付',
      receiptMethod: '银联支付',
    },
    {
      id: '5',
      name: '改单',
      receiptMethod: '改单',
    },
  ]

  const exportObj = (res: any) => {
    let arrObj: any = {}
    arrObj = {
      '!cols': [],
      '!fullref': `A1:P${(res?.length || 0) + 1}`,
      '!ref': `A1:P${(res?.length || 0) + 1}`,
      '!rows': [],
      A1: { t: 's', v: '小区名' },
      B1: { t: 's', v: '收费项目' },
      C1: { t: 's', v: '日期' },
      D1: { t: 's', v: '小票' },
      E1: { t: 's', v: '发票' },
      F1: { t: 's', v: '金额' },
      G1: { t: 's', v: '税费' },
      H1: { t: 's', v: '业主' },
      I1: { t: 's', v: '收款状态' },
      J1: { t: 's', v: '收款时间' },
      K1: { t: 's', v: '购买方' },
      L1: { t: 's', v: '备注' },
      M1: { t: 's', v: '楼宇备注' },
      N1: { t: 's', v: '退款日期' },
      O1: { t: 's', v: '退款原因' },
      P1: { t: 's', v: '摘要' },
    }
    res?.forEach((item: any, i: number) => {
      arrObj[`A${i + 2}`] = { t: 's', v: item?.projectName || '' }
      arrObj[`B${i + 2}`] = { t: 's', v: item?.billLxName || '' }
      arrObj[`C${i + 2}`] = { t: 's', v: item?.receiptDate || '' }
      arrObj[`D${i + 2}`] = { t: 's', v: item?.receiptNumber || '' }
      arrObj[`E${i + 2}`] = { t: 's', v: item?.invoiceNumber || '' }
      arrObj[`F${i + 2}`] = { t: 'n', v: item?.amount || 0 }
      arrObj[`G${i + 2}`] = { t: 'n', v: item?.tax || 0 }
      arrObj[`H${i + 2}`] = { t: 's', v: item?.ownerName || '' }
      arrObj[`I${i + 2}`] = { t: 'n', v: item?.status || '' }
      arrObj[`J${i + 2}`] = { t: 's', v: item?.payTime || '' }
      arrObj[`K${i + 2}`] = { t: 's', v: item?.buyer || '' }
      arrObj[`L${i + 2}`] = { t: 's', v: item?.remark || '' }
      arrObj[`M${i + 2}`] = { t: 's', v: item?.buildingRemark || '' }
      arrObj[`N${i + 2}`] = { t: 's', v: item?.refundDate || '' }
      arrObj[`O${i + 2}`] = { t: 's', v: item?.refundReason || '' }
      arrObj[`P${i + 2}`] = { t: 's', v: item?.summary || '' }
    })
    setArrExportList(arrObj)
  }

  const getInvoicingArr = (date: any, receiptMethod: any) => {
    const newDate: any = []
    if (timeBoo && receiptMethod === '转账') {
      newDate.push(moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD'))
    } else {
      newDate.push(date[0], date[1])
    }
    getInvoicing(
      newDate[0],
      newDate[1],
      receiptMethod,
      '',
      '',
      ownerNameValue,
      buyerValue,
      Number(amountValue),
      transactionId,
    )
      .then((res: any) => {
        let amountNum: any = 0
        let taxNum: any = 0
        let newObj: any = {}
        const newNameArr: any[] = []
        const newArr: any[] = []

        if (res?.length < 1) {
          setTotalObj({
            amount: 0,
            tax: 0,
          })
        }
        res?.map((item: any, i: any) => {
          amountNum += item.amount
          taxNum += item.tax
          newNameArr.push(item.projectName)
          if (i === res?.length - 1) {
            setTotalObj({
              amount: amountNum,
              tax: taxNum,
            })
          }
        })
        Array.from(new Set(newNameArr))?.map((item) => {
          newArr.push({ projectName: item, open: false })
        })
        newArr?.map((item) => {
          res?.map((items: any) => {
            if (item?.projectName === items?.projectName) {
              newObj = {
                ...newObj,
                [item?.projectName]: {
                  amount: (newObj[item?.projectName]?.amount || 0) + items.amount,
                  tax: (newObj[item?.projectName]?.tax || 0) + items.tax,
                },
              }
            }
          })
        })
        const newRes: any[] = []
        res?.map((item: any) => {
          if (item.projectName === '家化大厦' && item.billLxName === '电气设备维修维护费') {
            newRes.push({ ...item, billLxName: '电费' })
          } else {
            newRes.push(item)
          }
        })

        // const summedItems = newRes.reduce((accumulator: any, currentItem: any) => {
        //   const existingItem = accumulator.find(
        //     (item: any) =>
        //       item.receiptNumber === currentItem.receiptNumber &&
        //       item.projectName === '家化大厦' &&
        //       currentItem.projectName === '家化大厦' &&
        //       item.billLxName === '电费' &&
        //       currentItem.billLxName === '电费',
        //   )
        //   if (existingItem) {
        //     existingItem.amount += currentItem.amount
        //     existingItem.tax += currentItem.tax
        //   } else {
        //     accumulator.push({ ...currentItem })
        //   }
        //   return accumulator
        // }, [])
        setArrNameList(newArr)
        setAllTotalObj(newObj)
        setArrList(newRes)
        exportObj(newRes)
        setLoadBoo(false)
      })
      .catch(console.error)
  }

  useEffect(() => {
    setArrList([])
    setPaddingBoo(true)
    setTotalObj({
      amount: 0,
      tax: 0,
    })
    if (dateObj?.date) {
      setDateValue(dateObj?.date?.split('~') || ['', ''])
    }
    if (dateObj?.id && dateObj?.date && dateObj?.typeValue === '13') {
      getInvoicingArr(dateObj?.date?.split('~') || ['', ''], selectValue)
    }
  }, [dateObj?.id, dateObj?.date, dateObj?.typeValue])

  useEffect(() => {
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div className={classes.titleLeftBox}>
          <div style={{ width: '20%' }} className={classes.selectBigBox}>
            <BootstrapTooltip title={'点击可切换时间格式！'} placement="top">
              <div
                onClick={() => setTimeBoo(!timeBoo)}
                className={classNames([classes.titleText, classes.titleTextHover])}
              >
                时间：
              </div>
            </BootstrapTooltip>
            <div className={classes.dateBox}>
              {timeBoo ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['SingleInputDateRangeField']}>
                    <DateRangePicker
                      disableFuture
                      format="YYYY/MM/DD"
                      value={[dayjs(dateValue[0]), dayjs(dateValue[1])]}
                      onChange={(e: any) => {
                        const dateOne = e[0] && e[0] !== null ? dayjs(e[0]).format('YYYY-MM-DD') : ''
                        const dateTwo = e[1] && e[1] !== null ? dayjs(e[1]).format('YYYY-MM-DD') : ''
                        setDateValue([dateOne, dateTwo])
                      }}
                      slots={{ field: SingleInputDateRangeField }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              ) : (
                <input
                  type="month"
                  value={dayjs(dateValue[0]).format('YYYY-MM')}
                  onChange={(event: any) => {
                    setDateValue([
                      moment(event.target.value).startOf('months').format('YYYY-MM-DD'),
                      moment(event.target.value).endOf('months').format('YYYY-MM-DD'),
                    ])
                  }}
                />
              )}
            </div>
          </div>
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>付款： </div>
            <div className={classes.selectBox}>
              <FormControl className={classes.iptModal} fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  value={selectValue}
                  onChange={(event: any) => {
                    setSelectValue(event.target.value)
                  }}
                >
                  {selectItemList?.map((item, i) => {
                    return (
                      <MenuItem value={item?.receiptMethod} key={i}>
                        {item?.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>业主： </div>
            <div className={classes.selectBox}>
              <TextField
                className={classNames([classes.iptModal, classes.iptModalDelete])}
                value={ownerNameValue}
                onChange={(event: any) => {
                  setOwnerNameValue(event.target.value)
                }}
                id="outlined-basic"
                variant="outlined"
              />
              {ownerNameValue !== '' && (
                <HighlightOffIcon
                  className={classes.iconBox}
                  onClick={() => {
                    setOwnerNameValue('')
                  }}
                />
              )}
            </div>
          </div>
          <div className={classes.selectBigBox}>
            <div style={{ width: '10vh' }} className={classes.titleText}>
              购买方：
            </div>
            <div className={classes.selectBox}>
              <TextField
                className={classNames([classes.iptModal, classes.iptModalDelete])}
                value={buyerValue}
                onChange={(event: any) => {
                  setBuyerValue(event.target.value)
                }}
                id="outlined-basic"
                variant="outlined"
              />
              {buyerValue !== '' && (
                <HighlightOffIcon
                  className={classes.iconBox}
                  onClick={() => {
                    setBuyerValue('')
                  }}
                />
              )}
            </div>
          </div>
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>金额： </div>
            <div className={classes.selectBox}>
              <TextField
                className={classNames([classes.iptModal, classes.iptModalDelete])}
                value={amountValue}
                onChange={(event: any) => {
                  setAmountValue(event.target.value)
                }}
                id="outlined-basic"
                variant="outlined"
              />
              {amountValue !== '' && (
                <HighlightOffIcon
                  className={classes.iconBox}
                  onClick={() => {
                    setAmountValue('')
                  }}
                />
              )}
            </div>
          </div>
          {selectValue === '转账' && (
            <div className={classes.selectBigBox}>
              <div style={{ width: '10vh' }} className={classes.titleText}>
                流水号：
              </div>
              <div className={classes.selectBox}>
                <TextField
                  className={classNames([classes.iptModal, classes.iptModalDelete])}
                  value={transactionId}
                  onChange={(event: any) => {
                    setTransactionId(event.target.value)
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
                {buyerValue !== '' && (
                  <HighlightOffIcon
                    className={classes.iconBox}
                    onClick={() => {
                      setBuyerValue('')
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div>
          <Button
            onClick={() => {
              setLoadBoo(true)
              if (dateObj?.date === `${dateValue[0]}~${dateValue[1]}`) {
                getInvoicingArr(dateValue, selectValue)
              } else {
                navigate(
                  `?id=${dateObj?.id || 101}&date=${dateValue[0]}~${dateValue[1]}&typeValue=${dateObj?.typeValue}`,
                )
              }
            }}
            variant="contained"
            className={classes.btn}
          >
            查询
          </Button>
          <Button
            style={{ marginLeft: 15 }}
            onClick={() => {
              handleModalOpen()
            }}
            variant="contained"
            className={classes.btn}
          >
            上传
          </Button>

          {divWidth > 1280 && (
            <Button
              style={{ marginLeft: 15 }}
              onClick={() => {
                if (selectValue !== '' && dateValue !== '' && arrList?.length > 0) {
                  exportExcel(
                    arrExportList,
                    `${dateValue[0]}到${dateValue[1]}的${selectValue}开票数据-${moment
                      .utc(new Date())
                      .format('YYYY-MM-DD')}.xlsx`,
                  )
                }
              }}
              variant="contained"
              className={classes.btn}
            >
              导出
            </Button>
          )}
        </div>
      </div>
      <div className={classes.iphoneBox}>
        <div
          id="tableBigBox"
          style={{
            maxHeight: `calc( 100% - ${totalBox?.clientHeight}px )`,
          }}
          className={classes.tableBox}
        >
          <Table id="tableBox" stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                {headerArr?.map((item: any, i: any) => {
                  return (
                    <StyledTableCell
                      style={{ width: item.width }}
                      key={item.key}
                      align={i === 10 || i === 9 ? 'right' : 'left'}
                    >
                      {item.name}
                    </StyledTableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loadBoo ? (
                <StyledTableRow>
                  <StyledTableCell style={{ border: 0 }} colSpan={11}>
                    <div className={classes.loadingBox}>
                      <LoadingBox />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                arrNameList?.map((item: any, i: number) => {
                  return (
                    <React.Fragment key={i}>
                      <StyledTableRow>
                        <StyledTableCell align="right">
                          <div
                            onClick={() => {
                              const newBooArr: any[] = []
                              arrNameList?.map((items: any) => {
                                if (items.projectName === item.projectName) {
                                  newBooArr.push({ ...items, open: !items.open })
                                } else {
                                  newBooArr.push(items)
                                }
                              })
                              setArrNameList([...newBooArr])
                              getPaddingBoo()
                            }}
                            className={classes.thBox1}
                          >
                            {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            {item.projectName || ''}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="right" />
                        <StyledTableCell align="right" />
                        <StyledTableCell align="right" />
                        <StyledTableCell align="right" />
                        <StyledTableCell align="right" />
                        <StyledTableCell align="right" />
                        <StyledTableCell align="right" />
                        <StyledTableCell align="right" />
                        <StyledTableCell align="right">
                          <div className={classes.thBox}>
                            {!item.open ? allTotalObj[item?.projectName]?.amount?.toFixed(2) || 0 : ''}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <div className={classes.thBox}>
                            {!item.open ? allTotalObj[item?.projectName]?.tax?.toFixed(2) || 0 : ''}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell style={{ padding: 0 }} align="right" colSpan={11}>
                          <InvoicingCollapseBox
                            selectValue={selectValue}
                            getPaddingBoo={getPaddingBoo}
                            openBoo={item.open}
                            allArr={arrList?.filter((res: any) => res.projectName === item.projectName)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                      {item.open && (
                        <StyledTableRow>
                          <StyledSumTableCell style={{ width: '9%' }} align="left">
                            <div className={classNames([classes.thBox, classes.thFont, classes.sumBox])}>
                              {`${item.projectName} 求和` || ''}
                            </div>
                          </StyledSumTableCell>
                          <StyledSumTableCell align="left" />
                          <StyledSumTableCell align="left" />
                          <StyledSumTableCell align="left" />
                          <StyledSumTableCell align="left" />
                          <StyledSumTableCell align="left" />
                          <StyledSumTableCell align="left" />
                          <StyledSumTableCell align="left" />
                          <StyledSumTableCell align="left" />
                          <StyledSumTableCell align="right">
                            <div className={classNames([classes.thBox, classes.thFont])}>
                              {allTotalObj[item?.projectName]?.amount?.toFixed(2) || 0}
                            </div>
                          </StyledSumTableCell>
                          <StyledSumTableCell align="right">
                            <div className={classNames([classes.thBox, classes.thFont])}>
                              {allTotalObj[item?.projectName]?.tax?.toFixed(2) || 0}
                            </div>
                          </StyledSumTableCell>
                        </StyledTableRow>
                      )}
                    </React.Fragment>
                  )
                })
              )}
            </TableBody>
          </Table>
        </div>
        <div id="totalBox" style={{ paddingRight: paddingBoo ? 0 : 15 }} className={classes.totalBox}>
          <div>总计</div>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div>{totalObj?.amount?.toFixed(2) || 0}</div>
          <div>{totalObj?.tax?.toFixed(2) || 0}</div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        className={classes.modalBox}
      >
        <div className={classes.modalSmallBox}>
          <form
            id="formBox"
            action={`${config.url.API_URL}/invoices`}
            encType="multipart/form-data"
            method="post"
            target="frameName"
          >
            <input
              style={{ padding: '2px 10px' }}
              type="file"
              className={classes.iptBox}
              name="file"
              id="outlined-basic"
            />
            <button className={classes.btn1}>确定</button>
          </form>
          <iframe className={classes.iframeBox} src="" name="frameName" />
        </div>
      </Modal>
    </div>
  )
}

export default InvoicingListBox
