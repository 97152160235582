import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import { Collapse } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import BigBox from '@/components/adminBox/invoicingListBox/invoicingCollapseBox/collapseChildBox/bigBox'
import classNames from 'classnames'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  body: {
    width: '9%',
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '1.7vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '34px',
    '&>svg': {
      width: '3vh',
      height: '3vh',
      '@media (max-width: 1280px)': {
        width: '2vh',
        height: '2vh',
      },
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  thBox1: {
    width: '53%',
    overflowWrap: 'break-word',
  },
  amountBox: {
    display: 'flex',
    flexDirection: 'column',
    '&>span': {
      lineHeight: 1.3,
      '&:nth-of-type(2)': {
        fontSize: '1.5vh',
      },
    },
  },
}))

interface CollapseBoxProps {
  openBoo: boolean
  allArr: any
  selectValue: any
  getDateObj: (obj: any) => void
}

const CollapseChildBox = (props: CollapseBoxProps) => {
  const { classes } = useStyles()
  const { openBoo, allArr, selectValue, getDateObj } = props
  const [childArr, setChildArr] = useState<any>([])
  const [childBoo, setChildBoo] = useState(false)

  useEffect(() => {
    if (selectValue === '转账') {
      !childBoo && setChildBoo(true)
    } else {
      childBoo && setChildBoo(false)
    }
  }, [])

  useEffect(() => {
    setChildArr(allArr)
  }, [allArr])

  return (
    <Collapse in={openBoo} timeout={300} unmountOnExit>
      <Table stickyHeader aria-label="customized table">
        <TableBody>
          {childArr?.map((item: any, i: any) => {
            let colorStr: any
            if (childBoo) {
              if (item?.refundDate) {
                colorStr = 'red'
              } else {
                if (!item?.status || !item?.payTime) {
                  colorStr = 'rgba(0, 191, 255)'
                }
              }
            }

            return (
              <React.Fragment key={i}>
                <StyledTableRow>
                  <StyledTableCell align="left" />
                  <StyledTableCell style={{ width: '12%' }} align="left" />
                  <StyledTableCell style={{ width: '7%', color: colorStr }} align="left">
                    {item?.receiptDate}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '10%', color: colorStr, overflowWrap: 'anywhere' }} align="left">
                    {item?.receiptNumber}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '10%', color: colorStr, overflowWrap: 'anywhere' }} align="left">
                    {item?.invoiceNumber}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '14%', color: colorStr }} align="left">
                    {item?.buyer}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '5.5%', color: colorStr }} align="left">
                    {item?.ownerName}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '11%', color: colorStr }} align="left">
                    {childBoo ? (
                      <BigBox
                        transactionId={item?.transactionId}
                        receiptNumber={item?.receiptNumber}
                        label={item?.status}
                        payTime={item?.payTime}
                        getDateObj={getDateObj}
                      />
                    ) : (
                      <div className={classes.thBox}>{item?.payTime}</div>
                    )}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '8.5%', color: colorStr, overflowWrap: 'anywhere' }} align="left">
                    {item?.remark}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '6.5%', color: colorStr }} align="right">
                    <div className={classNames([classes.thBox, classes.amountBox])}>
                      <span>{item?.amount?.toFixed(2) || 0}</span>
                      {(item?.amount || 0) !== (item?.invoiceAmount || 0) && (
                        <span>({item?.invoiceAmount?.toFixed(2) || 0})</span>
                      )}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '6.5%', color: colorStr }} align="right">
                    <div className={classes.thBox}>{item?.tax?.toFixed(2) || 0}</div>
                  </StyledTableCell>
                </StyledTableRow>
              </React.Fragment>
            )
          })}
        </TableBody>
      </Table>
    </Collapse>
  )
}

export default CollapseChildBox
